import { useMemo } from "react";
import { usePreferences } from "../contexts/Preferences";
import { getPreferredYear } from "../helpers/years/getPreferredYear";
import { useConfiguration } from "./useConfiguration";

/**
 * @typedef {object} Params
 * @property {string | number} [environmentId] specific environment id to use instead of `bestEnvironment` id provided by `usePreferences`
 * @property {boolean} [fallbackOnBestEnvironment=true] if `environmentId` is not provided, use `bestEnvironment` id provided by `usePreferences` instead
 *
 * @param {Params} [params]
 * @returns {import("../types/Configuration").ConfigurationCurrentYear | null}
 */
export function usePreferredYear(params) {
  const { environmentId: _environmentId, fallbackOnBestEnvironment = true } =
    params ?? {};

  const { bestEnvironment } = usePreferences();

  const { configuration } = useConfiguration();

  const environmentId =
    _environmentId ??
    (fallbackOnBestEnvironment ? bestEnvironment?.id ?? "" : "");

  const preferredYear = useMemo(() => {
    return getPreferredYear({
      configuration,
      environmentId,
    });
  }, [configuration, environmentId]);

  return preferredYear;
}
