import { Button } from "@chakra-ui/react";
import getErrorStatus from "@raiden/library/helpers/api/getErrorStatus";
import useAuthAs from "@raiden/library/hooks/auth/as";
import useRequest from "@raiden/library/hooks/useRequest";
import generateApiUri from "@raiden/library/libraries/utils/generateApiUri";
import { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

function ButtonSendEmail() {
  const intl = useIntl();

  const { user } = useAuthAs();

  const { request, isLoading, toastSuccess, toastError } = useRequest();

  const [isSuccess, setIsSuccess] = useState(false);

  const handleClick = useCallback(() => {
    request(
      generateApiUri({
        id: "@customers.verificationNotification",
        parameters: {
          customerId: user?.id,
        },
      }),
      { method: "POST" },
    )
      .then(() => {
        toastSuccess({
          title: intl.formatMessage({ defaultMessage: "Email envoyé" }),
        });
        setIsSuccess(true);
      })
      .catch((response) => {
        const status = getErrorStatus({ error: response });
        if (status === 429) {
          toastError({
            title: intl.formatMessage({
              defaultMessage:
                "Un email a été envoyé récemment, veuillez patienter.",
            }),
          });
        } else {
          toastError({
            title: intl.formatMessage({
              defaultMessage: "Une erreur est survenue",
            }),
          });
        }
      });
  }, [intl, request, toastError, toastSuccess, user?.id]);

  return (
    <Button
      onClick={handleClick}
      isLoading={isLoading}
      isDisabled={isSuccess}
      colorScheme="brandPrimary"
      variant="solid"
      size="sm">
      {isSuccess ? (
        <FormattedMessage defaultMessage="Email envoyé" />
      ) : (
        <FormattedMessage defaultMessage="Renvoyer l'email de confirmation" />
      )}
    </Button>
  );
}

export default ButtonSendEmail;
