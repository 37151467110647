import { Box, Flex } from "@chakra-ui/react";
import { CUSTOMERS_STATES_VALUE_PENDING } from "@raiden/library/constants/customers";
import useAuthAs from "@raiden/library/hooks/auth/as";
import { useIntl } from "react-intl";
import PersistentAlert from "../../../components/PersistentAlert";
import UserGuard from "../../../components/UserGuard";
import { NavBarProvider } from "../../../hooks/useBottomNavBar";
import useGlobalSubscriptionState from "../../../hooks/useGlobalSubscriptionState";
import { HeaderProvider } from "../../../hooks/useHeader";
import { useIsValidated } from "../../../hooks/useIsValidated";
import BottomNavBar from "./BottomNavBar";
import ButtonSendEmail from "./ButtonSendEmail";
import Header from "./Header";

/**
 * @typedef {object} Props
 *
 * @param {import("react").PropsWithChildren<Props>} props
 */
function SignedInLayout({ children }) {
  const intl = useIntl();

  const { user } = useAuthAs();

  const { isValidated } = useIsValidated();

  const { globalSubscriptionState } = useGlobalSubscriptionState();

  return (
    <HeaderProvider>
      <UserGuard>
        {user?.["state"] === CUSTOMERS_STATES_VALUE_PENDING ? (
          <PersistentAlert
            title={intl.formatMessage({
              defaultMessage: "Confirmez votre adresse e-mail",
            })}
            description={intl.formatMessage(
              {
                defaultMessage:
                  "Pour confirmer la création de votre compte, merci de confirmer votre adresse e-mail en cliquant sur le lien présent dans l'email envoyé à l'adresse {email}.",
              },
              { email: user?.["email"] },
            )}
            addon={<ButtonSendEmail />}
          />
        ) : (
          !isValidated &&
          ["activated", "pending"].includes(globalSubscriptionState) && (
            <PersistentAlert
              title={intl.formatMessage({
                defaultMessage: "Votre compte est en attente de validation",
              })}
              description={intl.formatMessage({
                defaultMessage:
                  "Votre compte est en attente de validation par nos services.",
              })}
            />
          )
        )}

        <NavBarProvider>
          <Flex direction="column" minHeight="100vh">
            <Flex flexGrow={1} direction="column-reverse">
              <Box flexGrow={1}>{children}</Box>

              <Header />
            </Flex>

            <BottomNavBar />
          </Flex>
        </NavBarProvider>
      </UserGuard>
    </HeaderProvider>
  );
}

export default SignedInLayout;
