import { Box, Flex } from "@chakra-ui/react";
import Copyright from "../Copyright";

/**
 * @typedef {object} Props
 * @property {import("react").ReactElement} [header]
 *
 * @param {import("react").PropsWithChildren<Props>} props
 */
function PageLayout({ header, children }) {
  return (
    <Flex flexDirection="column-reverse">
      <Box flexShrink={0} mb=".5rem">
        <Copyright />
      </Box>

      <Box flexShrink={0}>{children}</Box>

      {header}
    </Flex>
  );
}

export default PageLayout;
