// deps
import { mode } from "@chakra-ui/theme-tools";

export default {
  variants: {
    "soft-rounded": (props) => {
      return {
        tab: {
          color: mode("gray.600", "gray.200")(props),
        },
        tabpanel: {
          padding: 0,
        },
      };
    },
  },
};
