import { IoPauseOutline, IoPlayOutline } from "react-icons/io5";
import { defineMessage } from "react-intl";

export const CUSTOMERS_STATES_VALUE_PENDING = "pending";
export const CUSTOMERS_STATES_VALUE_ENABLED = "enabled";
export const CUSTOMERS_STATES_VALUE_DISABLED = "disabled";

export const CUSTOMERS_STATES = {
  [CUSTOMERS_STATES_VALUE_PENDING]: {
    id: CUSTOMERS_STATES_VALUE_PENDING,
    colors: ["orange.600", "orange.300"],
    color: "green",
    icon: IoPlayOutline,
    isVisible: true,
  },
  [CUSTOMERS_STATES_VALUE_ENABLED]: {
    id: CUSTOMERS_STATES_VALUE_ENABLED,
    colors: ["green.600", "green.300"],
    color: "green",
    icon: IoPlayOutline,
    isVisible: true,
  },
  [CUSTOMERS_STATES_VALUE_DISABLED]: {
    id: CUSTOMERS_STATES_VALUE_DISABLED,
    colors: ["purple.600", "purple.300"],
    color: "purple",
    icon: IoPauseOutline,
    isVisible: true,
  },
};

export const CUSTOMERS_STATES_LIST = Object.values(CUSTOMERS_STATES);

export const CUSTOMERS_STATES_MESSAGE = defineMessage({
  defaultMessage:
    "{state, select, pending {en attente} enabled {activé} disabled {désactivé} other {inconnu}}",
});

export const CUSTOMERS_STATES_VERB_MESSAGE = defineMessage({
  defaultMessage:
    "{state, select, pending {mettre en attente} enabled {activer} disabled {désactiver} other {inconnu}}",
});

// ============================================================

export const CUSTOMERS_TYPES_VALUE_PARTICULAR = "particular";
export const CUSTOMERS_TYPES_VALUE_SOCIETY = "society";

export const CUSTOMERS_TYPES = {
  [CUSTOMERS_TYPES_VALUE_PARTICULAR]: {
    id: CUSTOMERS_TYPES_VALUE_PARTICULAR,
    label: defineMessage({ defaultMessage: "Particulier" }),
    description: defineMessage({
      defaultMessage: "L'utilisateur est un particulier",
    }),
    enabled: true,
  },
  [CUSTOMERS_TYPES_VALUE_SOCIETY]: {
    id: CUSTOMERS_TYPES_VALUE_SOCIETY,
    label: defineMessage({ defaultMessage: "Société" }),
    description: defineMessage({
      defaultMessage: "L'utilisateur est une société",
    }),
    enabled: false,
  },
};

export const CUSTOMERS_TYPES_LIST = Object.values(CUSTOMERS_TYPES);

export const CUSTOMERS_ENABLED_TYPES_LIST = CUSTOMERS_TYPES_LIST.filter(
  (type) => type.enabled,
);

export const CUSTOMERS_TYPES_MESSAGE = defineMessage({
  defaultMessage:
    "{type, select, particular {particulier} society {société} other {inconnu}}",
});

// ============================================================

export const CUSTOMERS_SEARCH_TABS_MESSAGE = defineMessage({
  defaultMessage:
    "{tab, select, all {Tous} customers {Clients} sellers {Vendeurs} trash {Corbeille} pending_moderation {En attente de confirmation} moderated {Modéré} other {Inconnu}}",
  description: "tab",
});

export const CUSTOMERS_SEARCH_TABS_VALUE_ALL = {
  value: "all",
  fields: [],
  defaultFields: [],
  colors: null,
  metaKey: "count",
};
export const CUSTOMERS_SEARCH_TABS_VALUE_TRASH = {
  value: "trash",
  fields: [{ name: "only_trashed", value: 1 }],
  defaultFields: [{ name: "only_trashed", value: undefined }],
  colors: ["red.600", "red.400"],
  metaKey: null,
};
export const CUSTOMERS_SEARCH_TABS = [
  CUSTOMERS_SEARCH_TABS_VALUE_ALL,
  CUSTOMERS_SEARCH_TABS_VALUE_TRASH,
];

// ============================================================

export const CUSTOMERS_SORT_VALUE_CREATE_AT_ASC = "created_asc";
export const CUSTOMERS_SORT_VALUE_CREATE_AT_DESC = "created_desc";
export const CUSTOMERS_SORT_VALUE_USERNAME_AT_ASC = "username_asc";
export const CUSTOMERS_SORT_VALUE_USERNAME_AT_DESC = "username_desc";
export const CUSTOMERS_SORT_VALUE_EMAIL_ASC = "email_asc";
export const CUSTOMERS_SORT_VALUE_EMAIL_DESC = "email_desc";

export const CUSTOMERS_SORTS = {
  [CUSTOMERS_SORT_VALUE_CREATE_AT_ASC]: {
    id: CUSTOMERS_SORT_VALUE_CREATE_AT_ASC,
  },
  [CUSTOMERS_SORT_VALUE_CREATE_AT_DESC]: {
    id: CUSTOMERS_SORT_VALUE_CREATE_AT_DESC,
  },
  [CUSTOMERS_SORT_VALUE_USERNAME_AT_ASC]: {
    id: CUSTOMERS_SORT_VALUE_USERNAME_AT_ASC,
  },
  [CUSTOMERS_SORT_VALUE_USERNAME_AT_DESC]: {
    id: CUSTOMERS_SORT_VALUE_USERNAME_AT_DESC,
  },
  [CUSTOMERS_SORT_VALUE_EMAIL_ASC]: {
    id: CUSTOMERS_SORT_VALUE_EMAIL_ASC,
  },
  [CUSTOMERS_SORT_VALUE_EMAIL_DESC]: {
    id: CUSTOMERS_SORT_VALUE_EMAIL_DESC,
  },
};

export const CUSTOMERS_SORT_LIST = Object.values(CUSTOMERS_SORTS);

export const CUSTOMERS_SORT_MESSAGE = defineMessage({
  defaultMessage:
    "{sort, select, created_asc {Date de création croissante} created_desc {Date de création décroissante} username_asc {Pseudonyme A ⇨ Z} username_desc {Pseudonyme Z ⇨ A}email_asc {Email A ⇨ Z} email_desc {Email Z ⇨ A} other {{sort}}}",
});

// ============================================================

export const CUSTOMERS_GENDERS_VALUE_MALE = "male";
export const CUSTOMERS_GENDERS_VALUE_FEMALE = "female";
export const CUSTOMERS_GENDERS_VALUE_OTHER = "other";

export const CUSTOMERS_GENDERS_MESSAGE = defineMessage({
  defaultMessage:
    "{gender, select, male {Monsieur} female {Madame} other {Autre}}",
});

export const CUSTOMERS_GENDERS_LIST = [
  CUSTOMERS_GENDERS_VALUE_MALE,
  CUSTOMERS_GENDERS_VALUE_FEMALE,
  CUSTOMERS_GENDERS_VALUE_OTHER,
];

// ============================================================

export const CUSTOMERS_PARENTSHIPS_VALUE_GRANDFATHER = "grandfather";
export const CUSTOMERS_PARENTSHIPS_VALUE_BROTHER = "brother";
export const CUSTOMERS_PARENTSHIPS_VALUE_COUSIN = "cousin";
export const CUSTOMERS_PARENTSHIPS_VALUE_UNCLE = "uncle";
export const CUSTOMERS_PARENTSHIPS_VALUE_FAMILY = "family";
export const CUSTOMERS_PARENTSHIPS_VALUE_CHILDMINDER = "childminder";
export const CUSTOMERS_PARENTSHIPS_VALUE_SPOUSE = "spouse";
export const CUSTOMERS_PARENTSHIPS_VALUE_OTHER = "other";

/** @type {{[key: string]: {value: string, label: import("react-intl").MessageDescriptor}}} */
export const CUSTOMERS_PARENTSHIPS = {
  [CUSTOMERS_PARENTSHIPS_VALUE_GRANDFATHER]: {
    value: CUSTOMERS_PARENTSHIPS_VALUE_GRANDFATHER,
    label: defineMessage({ defaultMessage: "Grand-parent" }),
  },
  [CUSTOMERS_PARENTSHIPS_VALUE_BROTHER]: {
    value: CUSTOMERS_PARENTSHIPS_VALUE_BROTHER,
    label: defineMessage({ defaultMessage: "Frère ou soeur" }),
  },
  [CUSTOMERS_PARENTSHIPS_VALUE_COUSIN]: {
    value: CUSTOMERS_PARENTSHIPS_VALUE_COUSIN,
    label: defineMessage({ defaultMessage: "Cousin(e)" }),
  },
  [CUSTOMERS_PARENTSHIPS_VALUE_UNCLE]: {
    value: CUSTOMERS_PARENTSHIPS_VALUE_UNCLE,
    label: defineMessage({ defaultMessage: "Oncle ou tante" }),
  },
  [CUSTOMERS_PARENTSHIPS_VALUE_FAMILY]: {
    value: CUSTOMERS_PARENTSHIPS_VALUE_FAMILY,
    label: defineMessage({ defaultMessage: "Famille" }),
  },
  [CUSTOMERS_PARENTSHIPS_VALUE_CHILDMINDER]: {
    value: CUSTOMERS_PARENTSHIPS_VALUE_CHILDMINDER,
    label: defineMessage({ defaultMessage: "Assistant(e) maternelle" }),
  },
  [CUSTOMERS_PARENTSHIPS_VALUE_SPOUSE]: {
    value: CUSTOMERS_PARENTSHIPS_VALUE_SPOUSE,
    label: defineMessage({ defaultMessage: "Conjoint(e)" }),
  },
  [CUSTOMERS_PARENTSHIPS_VALUE_OTHER]: {
    value: CUSTOMERS_PARENTSHIPS_VALUE_OTHER,
    label: defineMessage({ defaultMessage: "Autre" }),
  },
};

export const CUSTOMERS_PARENTSHIPS_LIST = Object.values(CUSTOMERS_PARENTSHIPS);

// ============================================================

export const CUSTOMERS_COMPOSITIONS_VALUE_PARENTAL = "parental";
export const CUSTOMERS_COMPOSITIONS_VALUE_RECOMPOSED = "recomposed";
export const CUSTOMERS_COMPOSITIONS_VALUE_SINGLE_PARENT = "single_parent";
export const CUSTOMERS_COMPOSITIONS_VALUE_HOST = "host";

/** @type {{[key: string]: {value: string, label: import("react-intl").MessageDescriptor}}} */
export const CUSTOMERS_COMPOSITIONS = {
  [CUSTOMERS_COMPOSITIONS_VALUE_PARENTAL]: {
    value: CUSTOMERS_COMPOSITIONS_VALUE_PARENTAL,
    label: defineMessage({ defaultMessage: "Famille parentale" }),
  },
  [CUSTOMERS_COMPOSITIONS_VALUE_RECOMPOSED]: {
    value: CUSTOMERS_COMPOSITIONS_VALUE_RECOMPOSED,
    label: defineMessage({ defaultMessage: "Famille recomposée" }),
  },
  [CUSTOMERS_COMPOSITIONS_VALUE_SINGLE_PARENT]: {
    value: CUSTOMERS_COMPOSITIONS_VALUE_SINGLE_PARENT,
    label: defineMessage({ defaultMessage: "Famille monoparentale" }),
  },
  [CUSTOMERS_COMPOSITIONS_VALUE_HOST]: {
    value: CUSTOMERS_COMPOSITIONS_VALUE_HOST,
    label: defineMessage({ defaultMessage: "Famille d'accueil" }),
  },
};

export const CUSTOMERS_COMPOSITIONS_LIST = Object.values(
  CUSTOMERS_COMPOSITIONS,
);

// ============================================================

export const CUSTOMERS_CONTACTS_VISIBILITY_VALUE_PUBLIC = "public";
export const CUSTOMERS_CONTACTS_VISIBILITY_VALUE_ADMIN_ONLY = "admin_only";

/** @type {{[key: string]: {value: string, label: import("react-intl").MessageDescriptor}}} */
export const CUSTOMERS_CONTACTS_VISIBILITY = {
  [CUSTOMERS_CONTACTS_VISIBILITY_VALUE_PUBLIC]: {
    value: CUSTOMERS_CONTACTS_VISIBILITY_VALUE_PUBLIC,
    label: defineMessage({ defaultMessage: "Publique" }),
  },
  [CUSTOMERS_CONTACTS_VISIBILITY_VALUE_ADMIN_ONLY]: {
    value: CUSTOMERS_CONTACTS_VISIBILITY_VALUE_ADMIN_ONLY,
    label: defineMessage({ defaultMessage: "Administrateurs uniquement" }),
  },
};

export const CUSTOMERS_CONTACTS_VISIBILITY_LIST = Object.values(
  CUSTOMERS_CONTACTS_VISIBILITY,
);

// ============================================================
