import { Box, HStack, useBreakpoint, useDimensions } from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { HIGHLIGHTED_MENU_ITEMS } from "../../../constants/menu";
import useBottomNavBar from "../../../hooks/useBottomNavBar";
import { useScrollBlocker } from "../../../hooks/useScrollBlocker";
import CloseOnNavigation from "./Header/CloseOnNavigation";
import MenuHighlighted from "./Menu/MenuHighlighted";
import MenuStandard from "./Menu/MenuStandard";
import MenuButton from "./MenuButton";

/**
 * @typedef {object} Props
 *
 * @param {Props} props
 */
function BottomNavBar(props) {
  const [isOpened, setIsOpened] = useState(false);

  const { navBarHeight, setNavBarHeight } = useBottomNavBar();

  /** @type {import("react").MutableRefObject<HTMLDivElement | null>} */
  const navbarRef = useRef(null);

  const dimensions = useDimensions(navbarRef, true);

  useEffect(() => {
    dimensions && setNavBarHeight(dimensions.borderBox.height);
  }, [dimensions, setNavBarHeight]);

  const { block, unblock } = useScrollBlocker();

  const breakpoint = useBreakpoint("base");

  const open = useCallback(() => {
    block();
    setIsOpened(true);
  }, [block]);

  const close = useCallback(() => {
    unblock();
    setIsOpened(false);
  }, [unblock]);

  const onToggle = useCallback(() => {
    isOpened ? close() : open();
  }, [close, isOpened, open]);

  useEffect(() => {
    if (breakpoint !== "base") {
      close();
    }
  }, [breakpoint, close]);

  return (
    <>
      <CloseOnNavigation onClose={close} />

      <Box
        ref={navbarRef}
        display={{ base: "block", md: "none" }}
        position={isOpened ? "fixed" : "sticky"}
        left="0"
        right="0"
        bottom="0"
        backgroundColor="#fff"
        borderTopWidth="1px"
        padding=".5rem">
        <HStack spacing="0">
          <MenuHighlighted items={HIGHLIGHTED_MENU_ITEMS} />

          <MenuButton onClick={onToggle} isOpened={isOpened} />
        </HStack>
      </Box>

      <Box
        visibility={isOpened ? "visible" : "hidden"}
        position="fixed"
        top="0"
        left="0"
        right="0"
        bottom={navBarHeight}
        overflowY="scroll"
        backgroundColor="#fff"
        opacity={isOpened ? "1" : "0"}
        pointerEvents={isOpened ? "auto" : "none"}
        transition={
          isOpened
            ? "opacity .25s ease"
            : "all .25s ease, visibility .25s .25s ease"
        }>
        <MenuStandard />
      </Box>
    </>
  );
}

export default BottomNavBar;
