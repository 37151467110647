import { Avatar, Box, DarkMode, HStack, Image, Text } from "@chakra-ui/react";
import useAuthAs from "@raiden/library/hooks/auth/as";
import generateFrontPath from "@raiden/library/libraries/utils/generateFrontPath";
import NextLink from "next/link";
import { FormattedMessage, useIntl } from "react-intl";
import Copyright from "../../../../components/Copyright";
import { NOT_HIGHLIGHTED_MENU_ITEMS } from "../../../../constants/menu";
import MenuCore from "./MenuCore";
import MenuItemCore from "./MenuItem/MenuItemCore";
import MenuItemStandard from "./MenuItem/MenuItemStandard";
import SignOutButton from "./SignOutButton";

function MenuStandard() {
  const intl = useIntl();

  /** @type {import("@raiden/library/contexts/AuthAs").AuthAsContextValue<import("@raiden/library/types/Customer").Customer>} */
  const { user } = useAuthAs();

  return (
    <>
      <Box display={{ base: "block", md: "none" }}>
        <DarkMode>
          <NextLink href={generateFrontPath({ id: "@home" })} passHref>
            <HStack
              as="a"
              spacing="1rem"
              backgroundColor="brandPrimary.700"
              px="1rem"
              py=".5rem">
              <Image
                src={generateFrontPath({
                  id: "internal-assets",
                  parameters: { filePath: "logo.svg" },
                  includeBasePath: true,
                })}
                h="53px"
                alt="logo"
              />

              <Text>
                <FormattedMessage defaultMessage="Centre Louise Michel" />
              </Text>
            </HStack>
          </NextLink>
        </DarkMode>
      </Box>

      <Box px="1.5rem" py="1.5rem">
        <NextLink href={generateFrontPath({ id: "@account.account" })} passHref>
          <HStack
            as="a"
            spacing="1rem"
            alignItems="center"
            backgroundColor="gray.50"
            borderRadius="0.375rem"
            p=".5rem"
            cursor="pointer"
            role="group">
            <Avatar name={`${user?.first_name} ${user?.last_name}`} />

            <Box>
              <Text
                fontSize="1rem"
                fontWeight={400}
                lineHeight="1.5rem"
                as="span">
                {`${user?.first_name} ${user?.last_name}`}
              </Text>

              <Text
                fontSize="0.75rem"
                fontWeight={600}
                lineHeight="1rem"
                _groupHover={{ textDecoration: "underline" }}>
                {intl.formatMessage({ defaultMessage: "Modifier mon profil" })}
              </Text>
            </Box>
          </HStack>
        </NextLink>
      </Box>

      <MenuCore
        menuItems={NOT_HIGHLIGHTED_MENU_ITEMS}
        render={({
          menuItems,
          closeMenuItem,
          openMenuItem,
          openedMenuItemsIds,
        }) => (
          <>
            {menuItems?.map?.((item) => (
              <MenuItemCore
                item={item}
                openedMenuItemsIds={openedMenuItemsIds}
                openMenuItem={openMenuItem}
                closeMenuItem={closeMenuItem}
                render={(params) => <MenuItemStandard {...params} />}
                key={item.id}
              />
            ))}
          </>
        )}
      />

      <Box borderTopWidth="1px" px="10px" py="1rem">
        <SignOutButton />
      </Box>

      <Copyright />
    </>
  );
}

export default MenuStandard;
