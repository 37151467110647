import { extendTheme } from "@chakra-ui/react";
import "@fontsource/rubik/300.css";
import "@fontsource/rubik/400.css";
import "@fontsource/rubik/500.css";
import "@fontsource/rubik/600.css";
import "@fontsource/rubik/700.css";
import Input from "./components/input";
import Select from "./components/select";
import Tabs from "./components/tabs";
import Text from "./components/text";
import Textarea from "./components/textarea";

export const PublicTheme = extendTheme({
  colors: {
    brandPrimary: {
      50: "#DFF7FE",
      100: "#93D5E9",
      200: "#71C0D8",
      300: "#40B2D6",
      400: "#12AFDF",
      500: "#00A3D5",
      600: "#0080A7",
      700: "#006787",
      800: "#004C63",
      900: "#00242F",
    },
    brandSecondary: {
      50: "#C9F8EB",
      100: "#92F3D9",
      200: "#62E2C0",
      300: "#4FD1AE",
      400: "#38B298",
      500: "#319786",
      600: "#2B8A70",
      700: "#077758",
      800: "#046349",
      900: "#003E2D",
    },
    darkGray: {
      50: "#F7FAFC",
      100: "#EDF2F7",
      200: "#E2E8F0",
      300: "#CBD5E0",
      400: "#A0AEC0",
      500: "#718096",
      600: "#4A5568",
      700: "#2D3748",
      800: "#1A202C",
      900: "#171923",
    },
  },
  components: {
    Input,
    Select,
    Text,
    Textarea,
    Tabs,
  },
  fonts: {
    heading: "Rubik",
    body: "Rubik",
  },
  shadows: {
    outline: "0 0 0 3px var(--chakra-colors-brandPrimary-500)",
  },
  styles: {
    global: {
      body: {
        overflowY: "scroll",
      },
      ".leaflet-popup-content-wrapper": {
        borderRadius: "8px !important",
      },
      ".grecaptcha-badge": {
        visibility: "hidden",
      },
      ".renderer h1": {
        fontFamily: "'Rubik', sans-serif",
        color: "#2D3748",
        fontSize: "3rem",
        fontWeight: "400",
        lineHeight: "normal",
      },
      ".renderer h2": {
        fontFamily: "'Rubik', sans-serif",
        color: "#2D3748",
        fontSize: "1.875rem",
        fontWeight: "400",
        lineHeight: "2.25rem",
      },
      ".renderer h3": {
        fontFamily: "'Rubik', sans-serif",
        color: "#718096",
        fontSize: "1.5rem",
        fontWeight: "400",
        lineHeight: "1.8rem",
      },
      ".renderer h4": {
        fontFamily: "'Rubik', sans-serif",
        color: "#718096",
        fontSize: "1.25rem",
        fontWeight: "400",
        lineHeight: "1.5rem",
      },
      ".renderer h5": {
        fontFamily: "'Rubik', sans-serif",
        color: "#718096",
        fontSize: "1.125rem",
        fontWeight: "400",
        lineHeight: "1.35rem",
      },
      ".renderer h6": {
        fontFamily: "'Rubik', sans-serif",
        color: "#718096",
        fontSize: "1rem",
        fontWeight: "400",
        lineHeight: "1.2rem",
      },
      ".renderer p, .renderer .paragraph, .renderer ul": {
        fontFamily: "'Rubik', sans-serif",
        color: "#363D63",
        fontSize: "1rem",
        fontWeight: "400",
        lineHeight: "1.5rem",
        mt: "0.875rem",
        ":first-child": {
          mt: 0,
        },
      },
      ".renderer ul": {
        marginLeft: "1.5rem",
      },
    },
  },
});
