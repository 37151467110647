import useAuthAs from "@raiden/library/hooks/auth/as";
import { usePreferredYear } from "@raiden/library/hooks/usePreferredYear";
import { useMemo } from "react";
import { isCustomerValidated } from "../helpers/customers/isCustomerValidated";

export function useIsValidated() {
  const preferredYear = usePreferredYear();

  /** @type {import("@raiden/library/contexts/AuthAs").AuthAsContextValue<import("@raiden/library/types/Customer").Customer>} */
  const { user } = useAuthAs();

  const isValidated = useMemo(() => {
    return isCustomerValidated({
      customer: user,
      currentYear: preferredYear,
    });
  }, [preferredYear, user]);

  return { isValidated };
}
