import { Button } from "@chakra-ui/react";
import useAuthAs from "@raiden/library/hooks/auth/as";
import generateApiUri from "@raiden/library/libraries/utils/generateApiUri";
import generateFrontPath from "@raiden/library/libraries/utils/generateFrontPath";
import { useRouter } from "next/router";
import { useIntl } from "react-intl";
import useRequest from "@raiden/library/hooks/useRequest";

/**
 * @typedef {object} Props
 *
 * @param {Props} props
 */
function SignOutButton(props) {
  const intl = useIntl();

  const { request, toastSuccess } = useRequest();

  const router = useRouter();

  const { logout } = useAuthAs();

  function handleClick() {
    request(
      generateApiUri({
        id: "@auth.logout",
      }),
      {
        method: "POST",
      },
    ).then(function () {
      toastSuccess({
        status: "success",
        title: intl.formatMessage({
          defaultMessage: "Déconnexion réussie",
        }),
      });
      router.push({ pathname: generateFrontPath({ id: "@account" }) });
      logout();
    });
  }

  return (
    <Button onClick={handleClick} colorScheme="red" width="full">
      {intl.formatMessage({ defaultMessage: "Déconnexion" })}
    </Button>
  );
}

export default SignOutButton;
