import useAuthAs from "@raiden/library/hooks/auth/as";
import { usePreferredYear } from "@raiden/library/hooks/usePreferredYear";
import { useMemo } from "react";

/**
 * @typedef {"unsubscribed" | "pending" | "activated"} GlobalSubscriptionState
 */

/**
 * @returns {{globalSubscriptionState: GlobalSubscriptionState}}
 */
function useGlobalSubscriptionState() {
  const { user } = useAuthAs();

  const preferredYear = usePreferredYear();

  /** @type {GlobalSubscriptionState} */
  const globalSubscriptionState = useMemo(() => {
    const currentYearGlobalSubscription = (
      user?.["global_subscriptions"] ?? []
    ).find((subscription) => subscription.year_id === preferredYear?.id);
    if (currentYearGlobalSubscription) {
      return currentYearGlobalSubscription.state;
    }
    return "unsubscribed";
  }, [preferredYear?.id, user]);

  return { globalSubscriptionState };
}

export default useGlobalSubscriptionState;
