import { Box, Link } from "@chakra-ui/react";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { useMemo } from "react";

/**
 * @param {object} params
 * @param {string} params.color
 */
function getAfterValues({ color }) {
  return {
    content: '""',
    position: "absolute",
    height: "0.25rem",
    left: "0",
    right: "0",
    bottom: "0",
    backgroundColor: color,
    borderRadius: "full",
  };
}

/**
 * @typedef {Object} Props
 * @property {string} title
 * @property {string} [href]
 * @property {string} color
 * @property {(params: {router: import("next/router").NextRouter}) => boolean} [isActive]
 * @property {"_blank" | undefined} [target]
 *
 * @param {Props} props
 */
function MenuItem({ title, href, color, isActive: _isActive, target }) {
  const router = useRouter();

  const isActive = useMemo(() => {
    if (_isActive) {
      return _isActive({ router });
    }
    return router.pathname === href;
  }, [_isActive, href, router]);

  return (
    <>
      {href !== undefined ? (
        <NextLink href={href} passHref>
          <Link
            as="a"
            position="relative"
            whiteSpace="nowrap"
            color={isActive ? color : undefined}
            fontSize="1.125rem"
            fontWeight={400}
            lineHeight="1.75rem"
            target={target}
            _after={isActive ? getAfterValues({ color }) : undefined}
            _hover={{
              color: color,
              _after: getAfterValues({ color }),
            }}>
            <Box px="1rem" py="1.625rem">
              {title}
            </Box>
          </Link>
        </NextLink>
      ) : (
        <Box
          position="relative"
          whiteSpace="nowrap"
          color={isActive ? color : undefined}
          fontSize="1.125rem"
          fontWeight={400}
          lineHeight="1.75rem"
          _after={isActive ? getAfterValues({ color }) : undefined}>
          <Box paddingX="1rem" paddingY="1.625rem">
            {title}
          </Box>
        </Box>
      )}
    </>
  );
}

export default MenuItem;
