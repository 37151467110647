import { Box, Link } from "@chakra-ui/react";
import NextLink from "next/link";

/**
 * @typedef {Object} Props
 * @property {string} href
 * @property {boolean} [replace]
 * @property {string} [target]
 * @property {import("react").ReactNode} children
 *
 * @param {Props} props
 */
function FooterLink({ href, replace = false, target, children }) {
  return (
    <Box>
      <NextLink href={href} replace={replace} passHref>
        <Link color="#fff" target={target}>
          {children}
        </Link>
      </NextLink>
    </Box>
  );
}

export default FooterLink;
