/**
 * @typedef {Object} Props
 * @property {import("react").ReactNode} children
 *
 * @param {Props} props
 */
function BlankLayout({ children }) {
  return <>{children}</>;
}

export default BlankLayout;
