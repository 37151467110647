import { Box, Icon, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import NextLink from "next/link";
import { useIntl } from "react-intl";
import useGlobalSubscriptionState from "../../../../../hooks/useGlobalSubscriptionState";
import { useIsValidated } from "../../../../../hooks/useIsValidated";

/**
 * @typedef {object} Props
 * @property {import("./MenuItemCore").Item} item
 * @property {boolean} isActive
 * @property {boolean} isActiveDeep
 *
 * @param {Props} props
 */
function MenuItemHighlighted({ item, isActive, isActiveDeep }) {
  const intl = useIntl();

  const color = useColorModeValue("gray.600", "#fff");
  const highlightedColor = useColorModeValue("brandPrimary.600", "#fff");
  const backgroundColor = useColorModeValue("gray.50", "brandPrimary.800");

  const { globalSubscriptionState } = useGlobalSubscriptionState();

  const { isValidated } = useIsValidated();

  const isDisabled =
    item.isDisabled?.({ globalSubscriptionState, isValidated }) ?? false;

  return (
    <NextLink href={item.href ?? ""} passHref>
      <VStack
        as="a"
        flexGrow={1}
        flexBasis="0px"
        target={item.target}
        justifyItems="stretch"
        color={isActive || isActiveDeep ? highlightedColor : color}
        spacing="0"
        role="group"
        opacity={isDisabled ? 0.5 : 1}
        pointerEvents={isDisabled ? "none" : "all"}>
        {item.icon && (
          <Box
            backgroundColor={
              isActive || isActiveDeep ? backgroundColor : "transparent"
            }
            borderRadius="6px"
            px="0.75rem"
            py="0.125rem">
            <Icon as={item.icon} verticalAlign="middle" boxSize="1.5rem" />
          </Box>
        )}

        <Text
          as="span"
          _groupHover={{
            textDecoration: isDisabled ? undefined : "underline",
          }}>
          {intl.formatMessage(item.title)}
        </Text>
      </VStack>
    </NextLink>
  );
}

export default MenuItemHighlighted;
