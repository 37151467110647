/**
 * @param {object} params
 * @param {import("@raiden/library/types/Customer").Customer} [params.customer]
 * @param {import("@raiden/library/types/Configuration").ConfigurationCurrentYear | null} params.currentYear
 */
export function isCustomerValidated({ customer, currentYear }) {
  if (!customer || !currentYear) {
    return false;
  }
  if (
    Object.values(customer?.["validations"] ?? {}).find(
      (validation) => validation.year_id === currentYear?.id,
    )
  ) {
    return true;
  }
  return false;
}
