import { Link, Text } from "@chakra-ui/react";
import { useIntl } from "react-intl";

/**
 * @typedef {object} Props
 *
 * @param {Props} props
 */
function Copyright(props) {
  const intl = useIntl();
  return (
    <Text textAlign="center" color="gray.300">
      <Link href="https://www.splitfire.fr/" target="blank">
        {intl.formatMessage(
          { defaultMessage: "© {year} Splitfire" },
          { year: new Date().getFullYear() },
        )}
      </Link>
    </Text>
  );
}

export default Copyright;
