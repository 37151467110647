import { useBreakpointValue } from "@chakra-ui/react";
import { createContext, useContext, useState } from "react";

/**
 * @type {React.Context<{
 * pageHeaderHeight: number,
 * setPageHeaderHeight: import("react").Dispatch<import("react").SetStateAction<number>>
 * }>}
 */
const HeaderContext = createContext({
  pageHeaderHeight: 0,
  setPageHeaderHeight: (height) => {},
});

export function useHeader() {
  const { pageHeaderHeight, setPageHeaderHeight } = useContext(HeaderContext);
  const desktopHeaderHeight = useBreakpointValue({ base: 0, md: 74 });
  const headerHeight = (desktopHeaderHeight ?? 0) + pageHeaderHeight;

  return { headerHeight, setPageHeaderHeight };
}

/**
 * @param {object} props
 * @param {React.ReactNode} props.children
 */
export function HeaderProvider({ children }) {
  const [pageHeaderHeight, setPageHeaderHeight] = useState(0);

  return (
    <HeaderContext.Provider value={{ pageHeaderHeight, setPageHeaderHeight }}>
      {children}
    </HeaderContext.Provider>
  );
}
