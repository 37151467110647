import { useIntl } from "react-intl";

/**
 * @typedef {object} Props
 *
 * @param {import("react").PropsWithChildren<Props>} props
 */
function MaintenanceLayout({ children }) {
  const intl = useIntl();

  return <>{intl.formatMessage({ defaultMessage: "Maintenance en cours." })}</>;
}

export default MaintenanceLayout;
