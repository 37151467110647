import useAuthAs from "@raiden/library/hooks/auth/as";
import generateFrontPath from "@raiden/library/libraries/utils/generateFrontPath";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { LAYOUTS } from "../constants/layouts";

export function useAutoLayout() {
  const { asPath } = useRouter();

  const { logged } = useAuthAs();

  const layout = useMemo(() => {
    if (asPath.startsWith(generateFrontPath({ id: "@account" }))) {
      return logged ? LAYOUTS.signedIn : LAYOUTS.signedOut;
    }

    return LAYOUTS.public;
  }, [asPath, logged]);

  return layout;
}
