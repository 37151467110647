import { Box, Text } from "@chakra-ui/react";
import generateFrontPath from "@raiden/library/libraries/utils/generateFrontPath";
import { FormattedMessage, useIntl } from "react-intl";
import Breadcrumbs from "../../components/Breadcrumbs";
import CenteredContent from "../../components/CenteredContent";
import Splash404Public from "../../components/Splash404Public";

function Error404Public() {
  const intl = useIntl();

  return (
    <Box px="1rem" pt="2rem" pb="6.25rem">
      <CenteredContent maxWidth="1280px">
        <Breadcrumbs
          renderOnMobile={true}
          items={[
            {
              title: intl.formatMessage({ defaultMessage: "Accueil" }),
              url: generateFrontPath({ id: "@home" }),
            },
            {
              title: intl.formatMessage({ defaultMessage: "Page introuvable" }),
            },
          ]}
        />

        <Text fontSize="48px" fontWeight={400} lineHeight="normal" mt="1rem">
          <FormattedMessage defaultMessage="Page introuvable" />
        </Text>

        <Box mt="2.5rem">
          <Splash404Public />
        </Box>
      </CenteredContent>
    </Box>
  );
}

export default Error404Public;
