import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import CenteredContent from "../CenteredContent";

/**
 * @typedef {Object} Props
 * @property {string} title
 * @property {string} description
 * @property {import("react").ReactNode} [addon]
 *
 * @param {Props} props
 */
function PersistentAlert({ title, description, addon }) {
  return (
    <Alert display="block" status="warning" w="full" px="1rem">
      <CenteredContent>
        <Stack>
          <HStack>
            <AlertIcon />

            <AlertTitle>
              <Text>{title}</Text>
            </AlertTitle>
          </HStack>

          <AlertDescription>
            <Stack>
              <Text flexGrow={1}>{description}</Text>

              {addon && <Box>{addon}</Box>}
            </Stack>
          </AlertDescription>
        </Stack>
      </CenteredContent>
    </Alert>
  );
}

export default PersistentAlert;
