// deps
// eslint-disable-next-line import/no-unresolved
import Router from "@splitfire-agency/raiden-library/dist/libraries/RouterImplementation/Router";
// eslint-disable-next-line import/no-unresolved
import Uri from "@splitfire-agency/raiden-library/dist/libraries/Uri";

export const frontBaseUri = new Uri(
  // @ts-ignore
  process.env.NEXT_PUBLIC_FRONT_SCHEME,
  null,
  process.env.NEXT_PUBLIC_FRONT_HOST,
  null,
  null,
  null,
  null,
);

export const frontBaseUriWithBasePath = new Uri(
  // @ts-ignore
  process.env.NEXT_PUBLIC_FRONT_SCHEME,
  null,
  process.env.NEXT_PUBLIC_FRONT_HOST,
  null,
  process.env.NEXT_PUBLIC_FRONT_BASE_PATH,
  null,
  null,
);

/**
 * Build a router.
 * @param {import ("@splitfire-agency/raiden-library/dist/libraries/Uri").default} baseUri
 * @return {import ("@splitfire-agency/raiden-library/dist/libraries/RouterImplementation/Router").default})}
 */
function buildRouter(baseUri) {
  const frontRouter = new Router();

  frontRouter.group(baseUri, function (frontRouter) {
    frontRouter.get("").setId("hostname");

    // assets (previewer)
    frontRouter
      .get("/public/<filePath>")
      .setId("assets")
      .setConstraint("filePath", /.*/);

    // internal assets (nextjs)
    frontRouter
      .get("/<filePath>")
      .setId("internal-assets")
      .setConstraint("filePath", /.*/);

    frontRouter.group("/<locale>", function (frontRouter) {
      frontRouter.get("").setId("@home");

      frontRouter.get("/password-recovery").setId("@password-recovery");
      frontRouter.get("/confirm-email").setId("@confirm-email");

      // <public-site>
      frontRouter.get("/contact").setId("@contact");
      frontRouter.get("/childhood").setId("@childhood");

      frontRouter.get("/pages/<postSlug>").setId("@page");

      frontRouter.group("/prestations", function (frontRouter) {
        frontRouter.get("").setId("@prestations.search");
        frontRouter.group("/<prestationSlug>", (frontRouter) => {
          frontRouter.get("").setId("@prestations.view");
        });
      });

      frontRouter.group("/articles", function (frontRouter) {
        frontRouter.get("").setId("@articles.search");
        frontRouter.group("/<articleSlug>", (frontRouter) => {
          frontRouter.get("").setId("@articles.view");
        });
      });
      // </public-site>

      // </account>
      frontRouter.group("/account", function (frontRouter) {
        frontRouter.get("/").setId("@account");
        frontRouter.get("/dashboard").setId("@account.dashboard");
        frontRouter.get("/account").setId("@account.account");
        frontRouter.get("/documents").setId("@account.documents");
        frontRouter.get("/subscriptions").setId("@account.subscriptions");
        frontRouter.get("/pages/<slug>").setId("@account.page");
        // prestations
        frontRouter.group("/prestations", function (frontRouter) {
          frontRouter.get("/").setId("@account.prestations");
          frontRouter.get("/search").setId("@account.prestations.search");
          frontRouter.group("/group", (frontRouter) => {
            frontRouter.get("").setId("@account.prestations.groups.view");
            frontRouter.group("/<groupId>", (frontRouter) => {
              frontRouter
                .get("")
                .setId("@account.prestations.groupsTypeFull.view");
              frontRouter
                .get("/subscribe")
                .setId("@account.prestations.groupsTypeFull.subscribe");
            });
          });
          frontRouter.group("/<prestationId>", (frontRouter) => {
            frontRouter.get("").setId("@account.prestations.view");
            frontRouter
              .get("/full-subscribe")
              .setId("@account.prestations.full-subscribe");
          });
        });
        // payments
        frontRouter.group("/payments", function (frontRouter) {
          frontRouter.get("/activities").setId("@account.payments.activities");
          frontRouter.get("/invoices").setId("@account.payments.invoices");
          frontRouter.get("/payments").setId("@account.payments.payments");
          frontRouter.get("/credits").setId("@account.payments.credits");
          frontRouter.get("/pay").setId("@account.payments.pay");
        });
        // holders
        frontRouter.group("/holders", function (frontRouter) {
          frontRouter.get("/").setId("@account.holders");
          frontRouter.get("/create").setId("@account.holders.create");
          frontRouter.group("/<holderId>", function (frontRouter) {
            frontRouter.get("/update").setId("@account.holders.update");
          });
        });
      });
      // </account>
    });
  });

  return frontRouter;
}

export const frontRouter = buildRouter(frontBaseUri);
export const frontRouterWithBasePath = buildRouter(frontBaseUriWithBasePath);
