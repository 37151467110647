import generateFrontPath from "@raiden/library/libraries/utils/generateFrontPath";
import {
  IoBookmarkOutline,
  IoCallOutline,
  IoCardOutline,
  IoCartOutline,
  IoDocumentOutline,
  IoDocumentsOutline,
  IoEarthOutline,
  IoHelpCircleOutline,
  IoHomeOutline,
  IoPeopleOutline,
} from "react-icons/io5";
import { defineMessage } from "react-intl";
import uniqid from "uniqid";

const slug = process.env.NEXT_PUBLIC_POST_SLUG_RULES_OF_PROCEDURE;

/** @type {import("../containers/Layouts/SignedInLayout/Menu/MenuItem/MenuItemCore").ItemDeclaration[]} */
const _MENU_ITEMS = [
  {
    title: defineMessage({ defaultMessage: "Accueil" }),
    icon: IoHomeOutline,
    href: generateFrontPath({ id: "@account.dashboard" }),
  },
  {
    title: defineMessage({ defaultMessage: "Activités" }),
    icon: IoCartOutline,
    href: generateFrontPath({ id: "@account.prestations.search" }),
    isHighlighted: true,
    isDisabled: ({ isValidated }) => {
      return !isValidated;
    },
  },
  {
    title: defineMessage({ defaultMessage: "Paiements" }),
    icon: IoCardOutline,
    href: generateFrontPath({ id: "@account.payments.activities" }),
    isHighlighted: true,
  },
  {
    title: defineMessage({ defaultMessage: "Adhérents" }),
    icon: IoPeopleOutline,
    href: generateFrontPath({ id: "@account.holders" }),
    isHighlighted: true,
  },
  {
    title: defineMessage({ defaultMessage: "Mes documents" }),
    description: defineMessage({
      defaultMessage: "Gérer les documents liés à votre compte",
    }),
    icon: IoDocumentsOutline,
    href: generateFrontPath({ id: "@account.documents" }),
  },
  {
    title: defineMessage({ defaultMessage: "Cotisations" }),
    description: defineMessage({
      defaultMessage: "Gestion des cotisations",
    }),
    icon: IoBookmarkOutline,
    href: generateFrontPath({ id: "@account.subscriptions" }),
  },
  {
    title: defineMessage({ defaultMessage: "Besoin d'aide ?" }),
    icon: IoHelpCircleOutline,
    href: "https://doc.cslouisemichel.fr/customer",
    target: "blank",
  },
  ...(slug
    ? [
        {
          title: defineMessage({ defaultMessage: "Règlement intérieur" }),
          icon: IoDocumentOutline,
          href: generateFrontPath({
            id: "@account.page",
            parameters: {
              slug,
            },
          }),
        },
      ]
    : []),
  {
    title: defineMessage({ defaultMessage: "Nous contacter" }),
    icon: IoCallOutline,
    href: generateFrontPath({ id: "@contact" }),
  },
  ...(process.env.NEXT_PUBLIC_FRONT_PUBLIC_REDIRECTS === "true"
    ? []
    : [
        {
          title: defineMessage({ defaultMessage: "Retour au site" }),
          icon: IoEarthOutline,
          href: generateFrontPath({ id: "@home" }),
          isActive: () => false,
        },
      ]),
];

/**
 * adds uniqid & depth to each item
 * @param {import("../containers/Layouts/SignedInLayout/Menu/MenuItem/MenuItemCore").ItemDeclaration[]} items
 * @param {number} [depth]
 * @returns {import("../containers/Layouts/SignedInLayout/Menu/MenuItem/MenuItemCore").Item[]}
 */
function prepare(items, depth = 0) {
  return items.map((item) => ({
    ...item,
    id: uniqid(),
    depth,
    children: item.children && prepare(item.children, depth + 1),
  }));
}

export const MENU_ITEMS = prepare(_MENU_ITEMS);

export const HIGHLIGHTED_MENU_ITEMS = MENU_ITEMS.filter(
  (item) => item.isHighlighted,
);

export const NOT_HIGHLIGHTED_MENU_ITEMS = MENU_ITEMS.filter(
  (item) => !item.isHighlighted,
);
