import { Box, Flex } from "@chakra-ui/react";
import Image from "@raiden/library/components/Image";
import generateFrontPath from "@raiden/library/libraries/utils/generateFrontPath";
import { useIntl } from "react-intl";

/**
 * @typedef {object} Props
 *
 * @param {import("react").PropsWithChildren<Props>} props
 */
function SignedOutLayout({ children }) {
  const intl = useIntl();

  return (
    <Flex minH="100vh">
      <Box flexGrow={1} flexBasis="480px" maxW="480px">
        {children}
      </Box>

      <Box flexGrow={1} h="100vh" position="sticky" top="0">
        <Box position="relative" h="100%">
          <Image
            src={generateFrontPath({
              id: "internal-assets",
              parameters: { filePath: `signed-out-background.png` },
              includeBasePath: true,
            })}
            layout="fill"
            objectFit="cover"
            alt={intl.formatMessage({
              defaultMessage: "centre louise michel",
            })}
          />
        </Box>
      </Box>
    </Flex>
  );
}

export default SignedOutLayout;
