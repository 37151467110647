import { Flex, VStack } from "@chakra-ui/react";
import useInterpolateTranslatedField from "@raiden/library/hooks/useInterpolateTranslatedField";
import { memo, useMemo } from "react";
import { MENU_PUBLIC_ITEMS_COLORS } from "../../../../../constants/menuPublic";
import {
  getMenuItemAdapterType,
  getMenuItemAdapterValues,
} from "../../../../../helpers/getMenuItemAdapterValues";
import { useConfigurationMenu } from "../../../../../hooks/useConfigurationMenu";
import ConnectButton from "../ConnectButton";
import MenuItem from "./MenuItem";

const Menu = memo(function Menu() {
  const { menu: menuHeader } = useConfigurationMenu({ alias: "header" });

  const translate = useInterpolateTranslatedField();

  const menuPublicItems = useMemo(() => {
    return menuHeader?.items
      .filter((menuItem) => getMenuItemAdapterType({ menuItem }) !== undefined)
      .map((menuItem) => {
        const values = getMenuItemAdapterValues({ menuItem, translate });
        return {
          title: values.title,
          href: values.href,
          target: values.target,
          isActive: undefined,
        };
      });
  }, [menuHeader?.items, translate]);

  return (
    <Flex flexGrow={1} justify="center">
      {menuPublicItems?.map((menuItem, index) => (
        <MenuItem
          title={menuItem.title}
          href={menuItem.href}
          color={
            MENU_PUBLIC_ITEMS_COLORS[index % MENU_PUBLIC_ITEMS_COLORS.length]
          }
          isActive={menuItem.isActive}
          target={menuItem.target}
          key={index}
        />
      ))}

      <VStack alignSelf="stretch" justify="center" ml="1rem">
        <ConnectButton />
      </VStack>
    </Flex>
  );
});

export default Menu;
