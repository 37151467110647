import useLocale from "@raiden/library/hooks/useLocale";
import Head from "next/head";
import packageJson from "../../package.json";

/**
 * @param {string} [title]
 */
function formatTitle(title) {
  return title
    ? `${title} - ${packageJson.displayName}`
    : packageJson.displayName;
}

/**
 * @typedef {Object} Props
 * @property {string} [title]
 * @property {string} [description]
 * @property {string} [keywords]
 * @property {boolean} [noIndex]
 * @property {(locale: string) => string} [links]
 * @property {(locale: string) => string} [canonicalLink]
 * @property {(locale: string) => string} [alternateLinks]
 * @property {"music.song" | "music.album" | "music.playlist" | "music.radio_station" | "video.movie" | "video.episode" | "video.tv_show" | "video.other" | "article" | "book" | "profile" | "website" | "product"} [ogType]
 * @property {string} [imageUrl]
 * @property {string} [ogUrl]
 * @property {Object} [structuredData]
 */
/**
 * @param {Props} props
 */
function Seo({
  title,
  description,
  keywords,
  noIndex,
  links,
  canonicalLink,
  alternateLinks,
  ogType,
  imageUrl,
  ogUrl,
  structuredData,
}) {
  const { locale: _currentLocale, locales = [], defaultLocale } = useLocale();

  const currentLocale =
    _currentLocale !== "default" ? _currentLocale : defaultLocale;
  const otherLocales = locales.filter(
    (locale) => locale !== "default" && locale !== currentLocale,
  );

  const formattedTitle = formatTitle(title);

  return (
    <Head>
      <title key="title">{formattedTitle}</title>

      <meta property="og:title" content={formattedTitle} key="og-title" />

      <meta name="twitter:card" content="summary" key="twitter-card" />

      <meta name="twitter:title" content={formattedTitle} key="twitter-title" />

      {description && (
        <>
          <meta name="description" content={description} key="description" />

          <meta
            property="og:description"
            content={description}
            key="og-description"
          />

          <meta
            name="twitter:description"
            content={description}
            key="twitter-description"
          />
        </>
      )}

      {ogType && <meta property="og:type" content={ogType} key="og-type" />}

      {imageUrl && (
        <>
          <meta property="og:image" content={imageUrl} key="og-image" />

          <meta name="twitter:image" content={imageUrl} key="twitter-image" />
        </>
      )}

      {ogUrl && <meta property="og:url" content={ogUrl} key="og-url" />}

      {keywords && <meta name="keywords" content={keywords} key="keywords" />}

      {noIndex && <meta name="robots" content="noindex" key="noindex" />}

      {structuredData && (
        <script type="application/ld+json" key="structured-data">
          {JSON.stringify(structuredData)}
        </script>
      )}

      {(links || canonicalLink) && currentLocale && (
        <link
          rel="canonical"
          href={(links ?? canonicalLink)?.(currentLocale)}
          key="canonical"
        />
      )}

      {(links || alternateLinks) &&
        otherLocales.map?.((locale, index) => (
          <link
            rel="alternate"
            href={(links ?? alternateLinks)?.(locale)}
            hrefLang={locale}
            key={index}
          />
        ))}
    </Head>
  );
}

export default Seo;
