import { Box, Button, Image, Stack, Text, Wrap } from "@chakra-ui/react";
import generateFrontPath from "@raiden/library/libraries/utils/generateFrontPath";
import NextLink from "next/link";
import { useIntl } from "react-intl";
import CenteredContent from "../../components/CenteredContent";
import PageHeader from "../../components/PageHeader";
import PageLayout from "../../components/PageLayout";

function Error404() {
  const intl = useIntl();

  return (
    <PageLayout
      header={
        <PageHeader
          title={intl.formatMessage({
            defaultMessage: "La page demandée n'existe pas",
          })}
          breadCrumbItems={[
            { title: intl.formatMessage({ defaultMessage: "Erreur 404" }) },
          ]}
        />
      }>
      <Box px="1rem" py="1rem">
        <CenteredContent>
          <Wrap
            flexDirection={{ base: "column", md: "row" }}
            justify={{ base: "center", md: "flex-start" }}
            align="center"
            spacing="1rem">
            <Image
              src={generateFrontPath({
                id: "internal-assets",
                parameters: { filePath: "illustrations/not-found.svg" },
                includeBasePath: true,
              })}
              alt={intl.formatMessage({
                defaultMessage: "Illustration d'une page introuvable",
              })}
            />

            <Stack
              alignItems={{ base: "center", md: "flex-start" }}
              spacing="1rem">
              <Text
                textAlign={{ base: "center", md: "left" }}
                fontSize="1.25rem"
                fontWeight={500}
                lineHeight="1.75rem">
                {intl.formatMessage({
                  defaultMessage:
                    "Nous sommes désolés, cette page est introuvable ou n’existe pas",
                })}
              </Text>

              <Text
                textAlign={{ base: "center", md: "left" }}
                colorScheme="gray">
                {intl.formatMessage({
                  defaultMessage:
                    "Retournez à l’accueil ou utilisez votre menu pour naviguer",
                })}
              </Text>

              <Box>
                <NextLink href={generateFrontPath({ id: "@account" })} passHref>
                  <Button as="a" colorScheme="brandPrimary">
                    {intl.formatMessage({
                      defaultMessage: "Revenir à l'accueil",
                    })}
                  </Button>
                </NextLink>
              </Box>
            </Stack>
          </Wrap>
        </CenteredContent>
      </Box>
    </PageLayout>
  );
}

export default Error404;
