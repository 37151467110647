import {
  Box,
  Button,
  Flex,
  Image,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import generateFrontPath from "@raiden/library/libraries/utils/generateFrontPath";
import { memo, useEffect } from "react";
import { useIntl } from "react-intl";
import HeaderMobileMenu from "./Menu";
import NextLink from "next/link";
import { useScrollBlocker } from "../../../../../hooks/useScrollBlocker";
import { Z_INDEX } from "../../../../../constants/zIndex";

const HeaderMobile = memo(function HeaderMobile() {
  const intl = useIntl();

  const { isOpen, onClose, onToggle } = useDisclosure();

  const { block, unblock } = useScrollBlocker();

  const isMobile = useBreakpointValue({ base: true, lg: false });

  useEffect(() => {
    if (isOpen) {
      block();
    } else {
      unblock();
    }
  }, [block, isOpen, unblock]);

  useEffect(() => {
    if (!isMobile) {
      onClose();
    }
  }, [isMobile, onClose]);

  return (
    <>
      <Box zIndex={Z_INDEX.header} display={{ base: "flex", xl: "none" }}>
        {isOpen && <HeaderMobileMenu onClose={onClose} />}
      </Box>

      <Flex
        zIndex={Z_INDEX.header}
        display={{ base: "flex", xl: "none" }}
        position={isOpen ? "fixed" : "sticky"}
        top="0"
        width="full"
        backgroundColor="#fff"
        boxShadow="inset 0px -1px 0px 0px #E2E8F0"
        px="1rem"
        py="1rem">
        <Box flexGrow={1}>
          <NextLink href={generateFrontPath({ id: "@home" })} passHref>
            <Box as="a" display="block" position="relative" w="100px">
              <Image
                position="absolute"
                top="-0.6rem"
                left="0"
                borderRadius="full"
                src={generateFrontPath({
                  id: "internal-assets",
                  parameters: { filePath: "logo.svg" },
                  includeBasePath: true,
                })}
                filter="drop-shadow(0px 1px 0px #E2E8F0)"
                alt={intl.formatMessage({
                  defaultMessage: "centre social louise michel",
                })}
              />
            </Box>
          </NextLink>
        </Box>

        <Button onClick={onToggle} height="48px" variant="ghost" p="0">
          <Image
            src={generateFrontPath({
              id: "internal-assets",
              parameters: { filePath: "menu.svg" },
              includeBasePath: true,
            })}
            alt={intl.formatMessage({
              defaultMessage: "menu",
            })}
          />
        </Button>
      </Flex>
    </>
  );
});

export default HeaderMobile;
