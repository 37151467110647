import { ChakraProvider } from "@chakra-ui/react";
import browser from "@raiden/library/constants/browser";
import { COOKIES_NAME_LIST } from "@raiden/library/constants/cookies";
import { frontBaseUri } from "@raiden/library/constants/routers/front";
import { USERS_USER_TYPE_VALUE_CUSTOMER } from "@raiden/library/constants/users";
import AuthAsProvider from "@raiden/library/contexts/AuthAs";
import { ConfigurationProvider } from "@raiden/library/contexts/Configuration";
import { GuardsProvider } from "@raiden/library/contexts/Guards";
import { MaintenanceModeProvider } from "@raiden/library/contexts/MaintenanceMode";
import { PreferencesProvider } from "@raiden/library/contexts/Preferences";
import { apiGetErrorStatus } from "@raiden/library/helpers/api";
import {
  nextGetConfiguration,
  nextGetCookies,
  nextGetLayout,
  nextGetUri,
  nextGetUser,
  nextRedirect,
} from "@raiden/library/helpers/next";
import { getPreferredYear } from "@raiden/library/helpers/years/getPreferredYear";
import generateApiUri from "@raiden/library/libraries/utils/generateApiUri";
import generateFrontPath from "@raiden/library/libraries/utils/generateFrontPath";
import generateFrontUri from "@raiden/library/libraries/utils/generateFrontUri";
import { OverlayProvider } from "@react-aria/overlays";
import { InitialVariablesProvider } from "@splitfire-agency/raiden-library/dist/contexts/InitialVariables";
import "focus-visible/dist/focus-visible";
import Head from "next/head";
import "react-image-crop/dist/ReactCrop.css";
import { IntlProvider } from "react-intl";
import { SWRConfig } from "swr";
import { useMaintenance } from "../../admin/hooks/useMaintenance";
import ErrorBoundary from "../components/ErrorBoundary";
import DefaultSeo from "../components/Seo/DefaultSeo";
import { LAYOUTS } from "../constants/layouts";
import CookiesConsent from "../containers/CookiesConsent";
import EnvironmentRequired from "../containers/EnvironmentRequired";
import ProgressBar from "../containers/ProgressBar";
import { isCustomerValidated } from "../helpers/customers/isCustomerValidated";
import { useAutoLayout } from "../hooks/useAutoLayout";
import getTranslationMessages from "../libraries/utils/getTranslationMessages";
import "../polyfills";
import intlPolyfills from "../polyfills/intl";

const swrConfigValue = {
  revalidateOnFocus: false,
  errorRetryCount: 0,
  refreshWhenOffline: false,
};

const ENVIRONMENT_IDS =
  process.env.NEXT_PUBLIC_FRONT_HOST !== undefined
    ? [process.env.NEXT_PUBLIC_FRONT_HOST]
    : [];

const CONFIGURATION_FIELDS = [
  "globals.covers",
  "globals.files",
  "globals.schooling_levels",
  "globals.payments.methods",
  "globals.global_subscription",
  "environments",
  "records_main_accounts",
  "auth",
  "years",
  "prestations_categories",
  "menus",
  "posts",
];

const CONFIGURATION_URL = generateApiUri({
  id: "@configuration",
  query: {
    fields: CONFIGURATION_FIELDS,
    environment_id: ENVIRONMENT_IDS,
  },
});

export default function App(props) {
  const {
    Component,
    initialProps,
    cookies,
    locale,
    translationMessages,
    acceptLanguage,
    maintenanceRequest,
    userData,
    environments,
    configuration,
    layout: _layoutName,
  } = props;

  const isMaintenance = useMaintenance({ cookies });

  /** @type {import("../types/Layouts").LayoutName} */
  const layoutName =
    isMaintenance || maintenanceRequest ? "maintenance" : _layoutName;

  const autoLayout = useAutoLayout(); // used to display error pages if the layout is not found

  const layout = LAYOUTS[layoutName] ?? autoLayout;

  return (
    <>
      <Head>
        <link
          rel="icon"
          href={generateFrontPath({
            id: "internal-assets",
            parameters: { filePath: "favicon.png" },
            includeBasePath: true,
          })}
        />
      </Head>

      <InitialVariablesProvider
        cookies={cookies}
        userData={userData}
        configuration={configuration}
        acceptLanguage={acceptLanguage}>
        <SWRConfig value={swrConfigValue}>
          <IntlProvider
            locale={locale}
            defaultLocale={process.env.NEXT_PUBLIC_DEFAULT_LOCALE}
            messages={translationMessages}>
            <ErrorBoundary>
              <MaintenanceModeProvider>
                <AuthAsProvider initialUser={userData?.data}>
                  <ConfigurationProvider
                    url={CONFIGURATION_URL}
                    initialConfiguration={configuration}
                    initialUser={userData?.data}>
                    <PreferencesProvider initialEnvironments={environments}>
                      <OverlayProvider style={{ minHeight: "100%" }}>
                        <ChakraProvider theme={layout.theme}>
                          <EnvironmentRequired>
                            <GuardsProvider guard={initialProps?.guard ?? {}}>
                              <layout.component>
                                <DefaultSeo />

                                <ProgressBar />

                                <Component {...initialProps} />
                              </layout.component>
                            </GuardsProvider>
                          </EnvironmentRequired>

                          <CookiesConsent />
                        </ChakraProvider>
                      </OverlayProvider>
                    </PreferencesProvider>
                  </ConfigurationProvider>
                </AuthAsProvider>
              </MaintenanceModeProvider>
            </ErrorBoundary>
          </IntlProvider>
        </SWRConfig>
      </InitialVariablesProvider>
    </>
  );
}

/**
 * @param {object} appProps
 * @param {import("next").NextComponentType<import("../types/AppContext").PageContext, import("../types/Page").PageInitialProps>} appProps.Component
 * @param {import("next/dist/shared/lib/utils").AppTreeType} appProps.AppTree
 * @param {import("next").NextPageContext} appProps.ctx
 * @param {import("next/router").default} appProps.router
 */
App.getInitialProps = async function (appProps) {
  const {
    Component,
    router: { locale },
  } = appProps;

  const defaultLocale = process.env.NEXT_PUBLIC_DEFAULT_LOCALE;

  const { whitelistCookies } = await nextGetCookies({
    req: appProps.ctx.req,
    whitelist: {
      NEXT_LOCALE: true,
    },
  });

  const { cookies } = await nextGetCookies({
    req: appProps.ctx.req,
    whitelist: COOKIES_NAME_LIST.reduce(function (cookies, { id: cookieName }) {
      cookies[cookieName] = true;
      return cookies;
    }, {}),
  });

  // only fetch configuration on the server
  const configurationResponse =
    typeof window === "undefined"
      ? await nextGetConfiguration({
          cookies,
          baseUri: frontBaseUri,
          // @ts-ignore
          locale,
          req: appProps.ctx.req,
          environmentsIds: ENVIRONMENT_IDS,
          fields: CONFIGURATION_FIELDS,
        })
      : null;

  const configuration = configurationResponse?.configuration;

  const [initialProps, translationMessages, userResponse] = await Promise.all([
    Component.getInitialProps?.({
      ...appProps.ctx,
      configuration: configuration,
    }),
    // @ts-ignore
    getTranslationMessages(locale, defaultLocale),
    nextGetUser({
      cookies,
      baseUri: frontBaseUri,
      locale,
      req: appProps.ctx.req,
    }),
    intlPolyfills(locale),
  ]);
  const configurationResponseError = configurationResponse?.error;

  const userData = userResponse?.data;
  const user = userResponse?.user;
  const logged = userResponse?.logged;
  const userResponseError = userResponse?.error;

  const tooManyRequest =
    429 === apiGetErrorStatus({ error: configurationResponseError }) ||
    429 === apiGetErrorStatus({ error: userResponseError });

  const maintenanceRequest =
    503 === apiGetErrorStatus({ error: configurationResponseError }) ||
    503 === apiGetErrorStatus({ error: userResponseError });

  // @ts-ignore
  const uri = nextGetUri({
    req: appProps.ctx.req,
    asPath: appProps.ctx.asPath,
  });

  // customerValidated guard to protect pages
  const environmentId = configuration?.environments?.[0]?.id;
  if (
    initialProps?.guards?.customerValidation === true &&
    configuration !== undefined &&
    environmentId !== undefined
  ) {
    const preferredYear = getPreferredYear({
      configuration,
      environmentId,
    });
    const isValidated = isCustomerValidated({
      customer: user,
      currentYear: preferredYear,
    });
    if (!isValidated && appProps.ctx.res) {
      await nextRedirect({
        code: 302,
        redirectionUri: generateFrontUri({
          id: "@account",
          includeBasePath: true,
        }),
        res: appProps.ctx.res,
      });
    }
  }

  const layout = await nextGetLayout({
    userType: USERS_USER_TYPE_VALUE_CUSTOMER,
    pageLayout: initialProps?.layout,
    tooManyRequest,
    // @ts-ignore
    res: appProps.ctx.res,
    logged,
    user,
    redirections: {
      signedOut: generateFrontUri({
        id: "@account",
        query: {
          next: encodeURIComponent(
            `${uri.getPath()}${uri.getQuery() ? `?${uri.getQuery()}` : ""}`,
          ),
        },
        includeBasePath: true,
      }),
      signedIn: generateFrontUri({
        id: "@account.dashboard",
        includeBasePath: true,
      }),
    },
  });

  const acceptLanguage = browser
    ? window.navigator.languages.join(",")
    : appProps.ctx.req?.headers["accept-language"];

  return {
    initialProps,
    locale,
    cookies: whitelistCookies,
    translationMessages,
    acceptLanguage,
    maintenanceRequest,
    userData,
    environments: configuration?.environments,
    configuration,
    layout,
  };
};
