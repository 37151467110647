import {
  Box,
  Button,
  HStack,
  Image,
  Stack,
  Text,
  Wrap,
} from "@chakra-ui/react";
import { useConfiguration } from "@raiden/library/hooks/useConfiguration";
import useInterpolateTranslatedField from "@raiden/library/hooks/useInterpolateTranslatedField";
import generateFrontPath from "@raiden/library/libraries/utils/generateFrontPath";
import { motion } from "framer-motion";
import NextLink from "next/link";
import { memo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

/**
 * @param {{
 * accept: () => void,
 * refuse: () => void,
 * close: () => void,
 * }} param0
 * @returns
 */
function CookiesModal({ accept, refuse, close }) {
  const intl = useIntl();

  const { configuration } = useConfiguration();

  const configurationPostCGU = configuration.posts?.page.find(
    (post) => post.alias === process.env.NEXT_PUBLIC_POST_ALIAS_CGU,
  );

  const translate = useInterpolateTranslatedField();

  return (
    <motion.div
      style={{ position: "fixed", left: 0, bottom: 0 }}
      initial={{
        transform: "translateY(25%)",
        opacity: 0,
      }}
      animate={{
        transform: "translateY(0%)",
        opacity: 1,
      }}
      exit={{
        transform: "translateY(25%)",
        opacity: 0,
      }}>
      <Box
        role="dialog"
        aria-labelledby="modal-cookies-title"
        aria-describedby="modal-cookies-description"
        m="1rem"
        bg="#fff"
        p="1rem"
        maxW="700px"
        boxShadow="0px 2px 4px rgba(57, 58, 151, 0.25);">
        <HStack spacing="1rem">
          <Box
            display={{ base: "none", lg: "block" }}
            alignSelf="flex-start"
            flexShrink={0}>
            <Image
              src={generateFrontPath({
                id: "internal-assets",
                parameters: { filePath: "cookies.svg" },
                includeBasePath: true,
              })}
              alt={intl.formatMessage({ defaultMessage: "Cookies" })}
            />
          </Box>

          <Stack spacing="1.25rem">
            <HStack spacing="0">
              <Image
                src={generateFrontPath({
                  id: "internal-assets",
                  parameters: { filePath: "cookies.svg" },
                  includeBasePath: true,
                })}
                display={{ lg: "none" }}
                maxW="48px"
                alt={intl.formatMessage({ defaultMessage: "Cookies" })}
                mr=".5rem"
              />

              <Text
                id="modal-cookies-title"
                flexGrow={1}
                fontSize="2rem"
                lineHeight="2.4881rem"
                color="secondary">
                <FormattedMessage defaultMessage="Utilisation des cookies" />
              </Text>
            </HStack>

            <Text
              id="modal-cookies-description"
              fontSize="1.125rem"
              lineHeight="1.3994rem">
              <FormattedMessage defaultMessage="Notre site utilise des cookies pour vous proposer du contenu personnalisé. Ces informations sont conservées 6 mois." />
            </Text>

            {configurationPostCGU && (
              <Box>
                <NextLink
                  href={generateFrontPath({
                    id: "@page",
                    parameters: {
                      postSlug: `${configurationPostCGU.id}-${translate(
                        configurationPostCGU.slug,
                      )}`,
                    },
                  })}
                  passHref>
                  <Button
                    as="a"
                    size="sm"
                    variant="outline"
                    colorScheme="brandPrimary"
                    borderRadius="full"
                    height="auto"
                    py="0.5rem"
                    whiteSpace="pre-wrap"
                    textAlign="center">
                    <FormattedMessage defaultMessage="Voir les Conditions Générales d'Utilisation" />
                  </Button>
                </NextLink>
              </Box>
            )}

            <Wrap spacing="1rem" justify="flex-end">
              <Button
                onClick={close}
                variant="ghost"
                height="52px"
                borderRadius="full">
                <FormattedMessage defaultMessage="Fermer" />
              </Button>

              <Button
                onClick={refuse}
                variant="ghost"
                height="52px"
                borderRadius="full">
                <FormattedMessage defaultMessage="Je refuse" />
              </Button>

              <Button
                onClick={accept}
                variant="solid"
                colorScheme="brandPrimary"
                height="52px"
                borderRadius="full">
                <FormattedMessage defaultMessage="J'accepte" />
              </Button>
            </Wrap>
          </Stack>
        </HStack>
      </Box>
    </motion.div>
  );
}

export default memo(CookiesModal);
