import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Stack,
} from "@chakra-ui/react";
import { usePreferences } from "@raiden/library/contexts/Preferences";
import { useIntl } from "react-intl";

/**
 * @typedef {object} Props
 *
 * @param {import("react").PropsWithChildren<Props>} props
 */
function EnvironmentRequired({ children }) {
  const { bestEnvironment } = usePreferences();

  const intl = useIntl();

  if (!bestEnvironment) {
    return (
      <Alert status="warning">
        <AlertIcon />

        <Stack>
          <AlertTitle>
            {intl.formatMessage({
              defaultMessage: "Aucun environment ne correspond à ce domaine.",
            })}
          </AlertTitle>

          <AlertDescription>
            {intl.formatMessage({
              defaultMessage:
                "Ajouter ce domaine à l'environnement correspondant dans l'interface d'administration.",
            })}
          </AlertDescription>
        </Stack>
      </Alert>
    );
  }

  return children;
}

export default EnvironmentRequired;
