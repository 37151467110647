import Script from "next/script";

export const getGoogleTagManagerScript = (key) => {
  if (!key) {
    return;
  }

  return (
    <Script id="google-tag-manager" strategy="afterInteractive">
      {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${key}');`}
    </Script>
  );
};

export const getGoogleAnalyticsScript = (key) => {
  if (!key) {
    return;
  }

  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${key}`}
        strategy="afterInteractive"
      />

      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${key}', {
            page_path: window.location.pathname + window.location.search,
          });
        `}
      </Script>
    </>
  );
};

export const trackPageView = (url, key) => {
  if (!key) {
    console.warn("unable to track, key is missing");
    return;
  }

  // @ts-ignore
  if (!window.gtag) {
    console.warn("unable to track, gtag is not initialized");
    return;
  }

  try {
    // @ts-ignore
    window.gtag("config", key, {
      page_path: url,
    });
  } catch (error) {
    console.warn("an error occured while tracking the page", error);
  }
};
