import {
  Box,
  Button,
  DarkMode,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import ConditionalWrapper from "@splitfire-agency/raiden-library/dist/components/ConditionalWrapper";
import { forwardRef } from "react";
import { IoMenuOutline } from "react-icons/io5";
import { useIntl } from "react-intl";

const MenuButton = forwardRef(
  /**
   * @typedef {object} Props
   * @property {boolean} isOpened
   * @property {"LightMode" | "DarkMode"} [variant]
   *
   * @param {Props & import("@chakra-ui/react").ButtonProps} props
   */
  function MenuButton({ isOpened, variant = "LightMode", ...otherProps }, ref) {
    const intl = useIntl();

    const color = useColorModeValue("gray.600", "#fff");
    const highlightedColor = useColorModeValue("brandPrimary.600", "#fff");
    const backgroundColor = useColorModeValue("gray.50", "brandPrimary.800");

    return (
      <ConditionalWrapper
        hasWrapper={variant === "DarkMode"}
        wrapper={(children) => <DarkMode>{children}</DarkMode>}>
        <Button
          variant="unstyled"
          display="flex"
          height="auto"
          flexDirection="column"
          fontWeight="normal"
          ref={ref}
          spacing="0"
          color={isOpened ? highlightedColor : color}
          role="group"
          flexBasis="0px"
          flexGrow={1}
          {...otherProps}>
          <Box
            backgroundColor={isOpened ? backgroundColor : "transparent"}
            borderRadius="6px"
            px="0.75rem"
            py="0.125rem">
            <Icon as={IoMenuOutline} verticalAlign="middle" boxSize="1.5rem" />
          </Box>

          <Text
            as="span"
            color="inherit"
            _groupHover={{ textDecoration: "underline" }}>
            {intl.formatMessage({ defaultMessage: "Plus" })}
          </Text>
        </Button>
      </ConditionalWrapper>
    );
  },
);

export default MenuButton;
