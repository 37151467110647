import generateFrontUri from "@raiden/library/libraries/utils/generateFrontUri";
import { useIntl } from "react-intl";
import Seo from "..";

function DefaultSeo() {
  const intl = useIntl();

  return (
    <Seo
      description={intl.formatMessage({
        defaultMessage:
          "Le Centre Social Louise Michel de Golbey est un lieu de proximité ouvert à tous, proposant un accueil convivial",
      })}
      imageUrl={generateFrontUri({
        id: "internal-assets",
        parameters: { filePath: "logo.svg" },
        includeBasePath: true,
      })}
    />
  );
}

export default DefaultSeo;
