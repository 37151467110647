import Error404 from "../containers/404";
import Error404Public from "../containers/404Public";
import BlankLayout from "../containers/BlankLayout";
import MaintenanceLayout from "../containers/Layouts/MaintenanceLayout";
import PublicLayout from "../containers/Layouts/PublicLayout";
import SignedInLayout from "../containers/Layouts/SignedInLayout";
import SignedOutLayout from "../containers/Layouts/SignedOutLayout";
import { AccountTheme } from "./themes/Account";
import { PublicTheme } from "./themes/Public";

/**
 * @template {string | number | symbol} T
 * @typedef {Record.<T, import("../types/Layouts").LayoutDeclaration<T>>} LayoutsRecord
 */

/**
 * @type {LayoutsRecord<import("../types/Layouts").LayoutName>}
 */
export const LAYOUTS = {
  maintenance: {
    name: "maintenance",
    component: MaintenanceLayout,
    theme: PublicTheme,
    error404Component: Error404Public,
  },
  signedOut: {
    name: "signedOut",
    component: SignedOutLayout,
    theme: AccountTheme,
    error404Component: Error404,
  },
  signedIn: {
    name: "signedIn",
    component: SignedInLayout,
    theme: AccountTheme,
    error404Component: Error404,
  },
  blank: {
    name: "blank",
    component: BlankLayout,
    theme: AccountTheme,
    error404Component: Error404,
  },
  public: {
    name: "public",
    component: PublicLayout,
    theme: PublicTheme,
    error404Component: Error404Public,
  },
};
