// deps
import { useCallback } from "react";

// hooks
import useLocale from "./useLocale";

/**
 * Hook qui retourne une fonction qui prend en paramètre un champ issue de l’API
 *   (de la forme { [lang]: string }) et qui va automatiquement
 *   afficher la valeur de la langue courante (si la valeur existe), ou à défaut la langue de base.
 *  Il est possible de forcer la locale en utilisant le paramètre forceLocale
 * @param {object} [param0]
 * @param {string} [param0.forceLocale]
 * @returns {(obj: {[key: string]: string} | undefined) => string | undefined}
 */
export default function useInterpolateTranslatedField({ forceLocale } = {}) {
  const { locale, defaultLocale } = useLocale();
  const currentLocale = forceLocale ?? locale;

  const interpolate = useCallback(
    /**
     * @param {{[key: string]: string} | undefined} field
     * @returns {string}
     */
    function (field) {
      if (
        null != field &&
        Object.prototype.hasOwnProperty.call(field, currentLocale)
      ) {
        return field[currentLocale];
      }

      return field?.[defaultLocale];
    },
    [currentLocale, defaultLocale],
  );

  return interpolate;
}
