import { Box, HStack, Icon, Stack, Text } from "@chakra-ui/react";
import { LightMode } from "@chakra-ui/system";
import ConditionalWrapper from "@splitfire-agency/raiden-library/dist/components/ConditionalWrapper";
import { AnimatePresence, motion } from "framer-motion";
import NextLink from "next/link";
import { useMemo } from "react";
import { IoChevronDown } from "react-icons/io5";
import { useIntl } from "react-intl";
import MenuItemCore from "./MenuItemCore";

const ANIMATION = {
  visible: {
    opacity: 1,
    height: "auto",
  },
  hidden: {
    opacity: 0,
    height: 0,
  },
};

/**
 * @typedef {object} Props
 * @property {import("./MenuItemCore").Item} item
 * @property {boolean} isActive
 * @property {boolean} isActiveDeep
 * @property {boolean} isOpened
 * @property {() => void} handleClickChevron
 * @property {string[]} openedMenuItemsIds
 * @property {(item: import("./MenuItemCore").Item) => void} openMenuItem
 * @property {(item: import("./MenuItemCore").Item) => void} closeMenuItem
 *
 * @param {Props} props
 */
function MenuItemStandard({
  item,
  isActive,
  isActiveDeep,
  isOpened,
  handleClickChevron,
  openedMenuItemsIds,
  openMenuItem,
  closeMenuItem,
}) {
  const intl = useIntl();

  const itemDescription = useMemo(() => {
    if (item.description !== undefined) {
      return intl.formatMessage(item.description);
    }
    if ((item.children ?? [])?.length > 0) {
      return item.children
        ?.map((itemChild) => intl.formatMessage(itemChild.title))
        .join(", ");
    }
    return null;
  }, [intl, item.children, item.description]);

  return (
    <LightMode>
      <Box role="group">
        <HStack flexShrink={0} spacing="0" position="relative" role="menuitem">
          <Box
            display={isActive ? "block" : "none"}
            position="absolute"
            left="0"
            top="0"
            bottom="0"
            w="4px"
            backgroundColor="brandSecondary.300"
            pointerEvents="none"
          />

          <ConditionalWrapper
            hasWrapper={typeof item.href === "string"}
            wrapper={(children) => (
              <NextLink href={item.href ?? ""} passHref>
                {children}
              </NextLink>
            )}>
            <Box
              flexGrow={1}
              as={item.href ? "a" : "button"}
              // @ts-ignore
              target={item.target}
              onClick={
                item.onClick ??
                ((item.children ?? []).length > 0 && !item.href
                  ? handleClickChevron
                  : undefined)
              }
              pl={`${40 * (item.depth ?? 0)}px`}
              aria-expanded={
                (item.children ?? []).length > 0 ? isOpened : null
              }>
              <Stack spacing="0.125rem" pl="1rem" pr="1rem" py="0.5rem">
                <HStack spacing="16px" width="full">
                  {item.icon && (
                    <Text
                      width="24px"
                      height="24px"
                      colorScheme={isActiveDeep ? "brandSecondary" : undefined}
                      _groupHover={{
                        color: "brandSecondary.300",
                      }}>
                      <Icon as={item.icon} boxSize="24px" />
                    </Text>
                  )}

                  <Text
                    as="div"
                    colorScheme={isActiveDeep ? "brandSecondary" : "gray.700"}
                    _groupHover={{
                      color: "brandSecondary.300",
                    }}>
                    {intl.formatMessage(item.title)}
                  </Text>
                </HStack>

                {itemDescription && (
                  <Text
                    textAlign="left"
                    fontSize="0.75rem"
                    color="gray.700"
                    pl="calc(24px + 1rem)">
                    {itemDescription}
                  </Text>
                )}
              </Stack>
            </Box>
          </ConditionalWrapper>

          {(item.children ?? []).length > 0 && (
            <Box
              as="button"
              onClick={handleClickChevron}
              px="1rem"
              py=".5rem"
              aria-expanded={isOpened}>
              <Icon
                as={IoChevronDown}
                verticalAlign="middle"
                boxSize="24px"
                color="gray.700"
                transform={`rotate(${isOpened ? 180 : 0}deg)`}
                transition="transform 0.25s ease-in-out"
                _hover={{ color: "brandSecondary.500" }}
              />
            </Box>
          )}
        </HStack>
      </Box>

      <AnimatePresence initial={false}>
        {(item.children ?? []).length > 0 && isOpened && (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={ANIMATION}
            style={{ flexShrink: 0, overflow: "hidden" }}>
            <Box backgroundColor="blackAlpha.100 ">
              {item.children?.map?.((item) => (
                <MenuItemCore
                  item={item}
                  openedMenuItemsIds={openedMenuItemsIds}
                  openMenuItem={openMenuItem}
                  closeMenuItem={closeMenuItem}
                  render={(params) => <MenuItemStandard {...params} />}
                  key={item.id}
                />
              ))}
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
    </LightMode>
  );
}

export default MenuItemStandard;
