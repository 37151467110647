import { createContext, useContext, useMemo, useState } from "react";

/**
 * @type {React.Context<{
 * navBarHeight: number;
 * setNavBarHeight: import("react").Dispatch<import("react").SetStateAction<number>>;
 * }>}
 **/
const NavBarContext = createContext({
  navBarHeight: 0,
  setNavBarHeight: (height) => {},
});

export default function useBottomNavBar() {
  return useContext(NavBarContext);
}

/**
 * @typedef {Object} Props
 *
 * @param {import("react").PropsWithChildren<Props>} props
 */
export const NavBarProvider = ({ children }) => {
  const [navBarHeight, setNavBarHeight] = useState(0);

  const value = useMemo(
    () => ({
      navBarHeight,
      setNavBarHeight,
    }),
    [navBarHeight],
  );

  return (
    <NavBarContext.Provider value={value}>{children}</NavBarContext.Provider>
  );
};
