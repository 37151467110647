import {
  Box,
  DarkMode,
  HStack,
  Image,
  LightMode,
  Link,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import generateFrontPath from "@raiden/library/libraries/utils/generateFrontPath";
import NextLink from "next/link";
import { forwardRef } from "react";
import { HIGHLIGHTED_MENU_ITEMS } from "../../../../constants/menu";
import MenuHighlighted from "../Menu/MenuHighlighted";
import MenuStandard from "../Menu/MenuStandard";
import MenuButton from "../MenuButton";
import CloseOnNavigation from "./CloseOnNavigation";

const MenuButtonContainer = forwardRef(
  /**
   * @typedef {Object} ContainerProps
   * @typedef {boolean} isOpened
   *
   * @param {ContainerProps} props
   */
  function MenuButtonContainer(props, ref) {
    return (
      <DarkMode>
        <MenuButton ref={ref} {...props} />
      </DarkMode>
    );
  },
);

function Header() {
  return (
    <DarkMode>
      <Box
        display={{ base: "none", md: "block" }}
        position="sticky"
        top="0"
        backgroundColor="brandPrimary.700"
        p="10px">
        <HStack spacing="0" maxWidth="1200px" margin="auto">
          <HStack flexGrow={1}>
            <NextLink
              href={generateFrontPath({
                id: "@account.dashboard",
              })}
              passHref>
              <Link as="a" display="block" overflow="hi">
                <Image
                  src={generateFrontPath({
                    id: "internal-assets",
                    parameters: { filePath: "logo.svg" },
                    includeBasePath: true,
                  })}
                  h="53px"
                  alt="logo"
                />
              </Link>
            </NextLink>
          </HStack>

          <HStack spacing="1.5rem">
            <MenuHighlighted items={HIGHLIGHTED_MENU_ITEMS} />

            <LightMode>
              <Popover
                modifiers={[
                  {
                    name: "offset",
                    options: {
                      offset: [0, 10],
                    },
                  },
                ]}>
                {({ isOpen, onClose }) => (
                  <>
                    <CloseOnNavigation onClose={onClose} />

                    <PopoverTrigger>
                      <MenuButtonContainer isOpened={isOpen} />
                    </PopoverTrigger>

                    <PopoverContent borderTopRadius="none">
                      <PopoverBody p="0">
                        <MenuStandard />
                      </PopoverBody>
                    </PopoverContent>
                  </>
                )}
              </Popover>
            </LightMode>
          </HStack>
        </HStack>
      </Box>
    </DarkMode>
  );
}

export default Header;
