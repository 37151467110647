import {
  Box,
  Button,
  DarkMode,
  HStack,
  Image,
  LightMode,
  Link,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useConfiguration } from "@raiden/library/hooks/useConfiguration";
import useInterpolateTranslatedField from "@raiden/library/hooks/useInterpolateTranslatedField";
import generateFrontPath from "@raiden/library/libraries/utils/generateFrontPath";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { FormattedMessage } from "react-intl";
import CenteredContent from "../../../../components/CenteredContent";
import {
  CONTACT_EMAIL,
  CONTACT_PHONE_NUMBER,
  CONTACT_PHONE_NUMBER_DISPLAY,
} from "../../../../constants/contact";
import {
  getMenuItemAdapterType,
  getMenuItemAdapterValues,
} from "../../../../helpers/getMenuItemAdapterValues";
import { useConfigurationMenu } from "../../../../hooks/useConfigurationMenu";
import FooterLink from "./FooterLink";

function Footer() {
  const { configuration } = useConfiguration();

  const { asPath } = useRouter();

  const replaceUrl = asPath.startsWith(
    generateFrontPath({ id: "@prestations.search" }),
  );

  const { menu: menuFooter } = useConfigurationMenu({ alias: "footer" });

  const translate = useInterpolateTranslatedField();

  return (
    <DarkMode>
      <Box mt={{ base: undefined, lg: "1.3125rem" }}>
        {/*
        <Box backgroundColor="brandPrimary.500">
          <Box px="1rem">
            <CenteredContent maxWidth="1280px">
              <Box
                position="relative"
                pt="2rem"
                pb="4rem"
                backgroundImage={`url(${generateFrontPath({
                  id: "internal-assets",
                  parameters: {
                    filePath: "email-newsletter.svg",
                  },
                  includeBasePath: true,
                })})`}
                backgroundRepeat="no-repeat"
                backgroundPosition="center right">
                <Image
                  display={{ base: "none", lg: "block" }}
                  position="absolute"
                  top="0"
                  w="full"
                  transform="translateY(calc(-100% + 1px))"
                  src={generateFrontPath({
                    id: "internal-assets",
                    parameters: {
                      filePath: "footer-wave-blue.svg",
                    },
                    includeBasePath: true,
                  })}
                  alt="centre-social-louise-michel"
                />

                <Newsletter />
              </Box>
            </CenteredContent>
          </Box>
        </Box>
        */}

        <Box backgroundColor="gray.700">
          <Box px="1rem">
            <CenteredContent maxWidth="1280px">
              <Box position="relative" py="2rem">
                <Image
                  display={{ base: "none", lg: "block" }}
                  position="absolute"
                  top="0"
                  w="full"
                  transform="translateY(calc(-100% + 1px))"
                  src={generateFrontPath({
                    id: "internal-assets",
                    parameters: {
                      filePath: "footer-wave-gray.svg",
                    },
                    includeBasePath: true,
                  })}
                  alt="centre-social-louise-michel"
                />

                <Stack
                  direction={{ base: "column", lg: "row" }}
                  spacing={{ base: "4rem", lg: "10rem" }}>
                  <Box flexShrink={0}>
                    <Image
                      src={generateFrontPath({
                        id: "internal-assets",
                        parameters: { filePath: "logo-transparent.svg" },
                        includeBasePath: true,
                      })}
                      width="240px"
                      margin={{ base: "0 auto", lg: "0" }}
                      alt="centre social louise michel"
                    />
                  </Box>

                  <SimpleGrid
                    flexGrow={1}
                    columns={{ base: 1, lg: 2 }}
                    gridGap="4rem">
                    <Stack spacing="0.75rem">
                      <Text
                        fontSize="1.5rem"
                        fontWeight={400}
                        lineHeight="1.8rem">
                        <FormattedMessage defaultMessage="Contact" />
                      </Text>

                      <Box>
                        <Text>
                          <FormattedMessage defaultMessage="1 rue Louis Blériot, 88190 Golbey" />
                        </Text>

                        <Box>
                          <Link
                            href={`tel:${CONTACT_PHONE_NUMBER}`}
                            fontSize="1.25rem"
                            fontWeight={500}
                            lineHeight="1.75rem"
                            color="white">
                            {CONTACT_PHONE_NUMBER_DISPLAY}
                          </Link>
                        </Box>

                        <Box>
                          <Link
                            href={`mailto:${CONTACT_EMAIL}`}
                            target="_blank"
                            color="white">
                            {CONTACT_EMAIL}
                          </Link>
                        </Box>
                      </Box>

                      <LightMode>
                        <Box>
                          <NextLink
                            href={generateFrontPath({ id: "@contact" })}
                            passHref>
                            <Button
                              as="a"
                              height="52px"
                              borderRadius="full"
                              colorScheme="brandSecondary">
                              <FormattedMessage defaultMessage="Nous contacter" />
                            </Button>
                          </NextLink>
                        </Box>
                      </LightMode>
                    </Stack>

                    <Box>
                      <Text
                        fontSize="1.5rem"
                        fontWeight={400}
                        lineHeight="1.8rem">
                        <FormattedMessage defaultMessage="Nos activités" />
                      </Text>

                      <Box mt="0.75rem">
                        {configuration.prestations_categories?.map(
                          (prestationCategories, index) => (
                            <FooterLink
                              href={generateFrontPath({
                                id: "@prestations.search",
                                query: {
                                  category_id: [prestationCategories.id],
                                },
                              })}
                              replace={replaceUrl}
                              key={index}>
                              {prestationCategories.name}
                            </FooterLink>
                          ),
                        )}
                      </Box>
                    </Box>

                    <Box>
                      <Text
                        fontSize="1.5rem"
                        fontWeight={400}
                        lineHeight="1.8rem">
                        <FormattedMessage defaultMessage="Retrouvez-nous sur vos réseaux sociaux préférés" />
                      </Text>

                      <Box mt="0.75rem">
                        <HStack spacing="2rem">
                          <Link
                            href="https://www.facebook.com/centresociallouisemichelgolbey"
                            target="_blank">
                            <Image
                              src={generateFrontPath({
                                id: "internal-assets",
                                parameters: { filePath: "social/facebook.svg" },
                                includeBasePath: true,
                              })}
                              alt="facebook"
                            />
                          </Link>

                          <Link
                            href="https://www.instagram.com/centre_louise_michel_golbey/"
                            target="_blank">
                            <Image
                              src={generateFrontPath({
                                id: "internal-assets",
                                parameters: {
                                  filePath: "social/instagram.svg",
                                },
                                includeBasePath: true,
                              })}
                              alt="facebook"
                            />
                          </Link>

                          <Link
                            href="https://www.youtube.com/channel/UCveiS4xB037HySo1TRXHnSg"
                            target="_blank">
                            <Image
                              src={generateFrontPath({
                                id: "internal-assets",
                                parameters: { filePath: "social/youtube.svg" },
                                includeBasePath: true,
                              })}
                              alt="facebook"
                            />
                          </Link>
                        </HStack>
                      </Box>
                    </Box>

                    <Box>
                      <Text
                        fontSize="1.5rem"
                        fontWeight={400}
                        lineHeight="1.8rem">
                        <FormattedMessage defaultMessage="Liens pratiques" />
                      </Text>

                      <Stack spacing="0" mt="0.75rem">
                        {menuFooter?.items
                          .filter(
                            (menuItem) =>
                              getMenuItemAdapterType({ menuItem }) !==
                              undefined,
                          )
                          .map((menuItem, index) => {
                            const { href, title, target } =
                              getMenuItemAdapterValues({ menuItem, translate });
                            return (
                              <>
                                {href === undefined ? (
                                  <Text color="#fff">{title}</Text>
                                ) : (
                                  <FooterLink href={href} target={target}>
                                    {title}
                                  </FooterLink>
                                )}
                              </>
                            );
                          })}
                      </Stack>
                    </Box>
                  </SimpleGrid>
                </Stack>

                <Text
                  fontSize="0.75rem"
                  fontWeight={400}
                  lineHeight="1rem"
                  textAlign="center"
                  mt="3rem">
                  <FormattedMessage defaultMessage="© 2023 Centre Social Louise Michel. Tous droits réservés." />
                </Text>
              </Box>
            </CenteredContent>
          </Box>
        </Box>
      </Box>
    </DarkMode>
  );
}

export default Footer;
