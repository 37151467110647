import { Box, Flex, Image } from "@chakra-ui/react";
import generateFrontPath from "@raiden/library/libraries/utils/generateFrontPath";
import { useRouter } from "next/router";
import { memo, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Menu from "./Menu";
import NextLink from "next/link";
import { Z_INDEX } from "../../../../../constants/zIndex";
import CenteredContent from "../../../../../components/CenteredContent";

const HeaderDesktop = memo(function HeaderDesktop() {
  const intl = useIntl();

  const [isTop, setIsTop] = useState(true);

  const router = useRouter();

  useEffect(() => {
    const handler = () => {
      setIsTop(window.scrollY === 0);
    };

    setIsTop(window.scrollY === 0);

    document.addEventListener("scroll", handler);

    return () => {
      document.removeEventListener("scroll", handler);
    };
  }, []);

  return (
    <Box
      zIndex={Z_INDEX.header}
      display={{ base: "none", xl: "block" }}
      position="sticky"
      top="0"
      backgroundColor="#fff"
      boxShadow="inset 0px -1px 0px 0px #E2E8F0">
      <Box px="1rem">
        <CenteredContent maxWidth="1280px">
          <Flex>
            <NextLink href={generateFrontPath({ id: "@home" })} passHref>
              <Box
                as="a"
                display="block"
                w="100px"
                position="relative"
                mr="1rem">
                <Image
                  position="absolute"
                  top="0.375rem"
                  left="0"
                  borderRadius="full"
                  transform={
                    isTop &&
                    router.pathname === generateFrontPath({ id: "@home" })
                      ? "translateX(40%) translateY(200%) scale(1.8)"
                      : undefined
                  }
                  transition="all .25s ease"
                  src={generateFrontPath({
                    id: "internal-assets",
                    parameters: { filePath: "logo.svg" },
                    includeBasePath: true,
                  })}
                  filter="drop-shadow(0px 1px 0px #E2E8F0)"
                  alt={intl.formatMessage({
                    defaultMessage: "centre social louise michel",
                  })}
                />
              </Box>
            </NextLink>

            <Menu />
          </Flex>
        </CenteredContent>
      </Box>
    </Box>
  );
});

export default HeaderDesktop;
