import { Button, Center, Icon, Stack, Text } from "@chakra-ui/react";
import generateFrontPath from "@raiden/library/libraries/utils/generateFrontPath";
import { IoCloseOutline } from "react-icons/io5";
import { FormattedMessage } from "react-intl";
import NextLink from "next/link";

function Splash404Public() {
  return (
    <Stack spacing="1.5rem" textAlign="center">
      <Stack spacing="1rem">
        <Icon
          as={IoCloseOutline}
          color="brandPrimary.300"
          boxSize="200px"
          mx="auto"
        />

        <Text
          fontSize="1.875rem"
          fontWeight={400}
          lineHeight="2.25rem"
          color="gray.500">
          <FormattedMessage defaultMessage="Erreur 404" />
        </Text>

        <Text fontSize="1.125rem" fontWeight={400} lineHeight="1.75rem">
          <FormattedMessage defaultMessage="Cette page n'existe pas ou a été supprimée. Vérifiez son adresse ou veuillez retourner à la page d'accueil." />
        </Text>
      </Stack>

      <Center>
        <NextLink href={generateFrontPath({ id: "@home" })} passHref>
          <Button
            as="a"
            borderRadius="full"
            height="52px"
            colorScheme="brandSecondary">
            <FormattedMessage defaultMessage="Retourner à la page d'accueil" />
          </Button>
        </NextLink>
      </Center>
    </Stack>
  );
}

export default Splash404Public;
