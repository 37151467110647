import {
  Box,
  Flex,
  Icon,
  IconButton,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import NextLink from "next/link";
import { isValidElement, useLayoutEffect, useRef } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { useIntl } from "react-intl";
import { useHeader } from "../../hooks/useHeader";
import Breadcrumbs from "../Breadcrumbs";
import CenteredContent from "../CenteredContent";

/**
 * @typedef {object} Props
 * @property {import("react").ReactNode} title
 * @property {string} [backUrl]
 * @property {import("../Breadcrumbs").BreadCrumbItem[]} [breadCrumbItems]
 * @property {import("react").ReactElement} [addon]
 *
 * @param {import("react").PropsWithChildren<Props>} props
 */
function PageHeader({ title, backUrl, breadCrumbItems, addon, children }) {
  const intl = useIntl();

  /** @type {import("react").MutableRefObject<HTMLDivElement | null>} */
  const headerRef = useRef(null);

  const { setPageHeaderHeight } = useHeader();

  const isMobile = useBreakpointValue({ base: true, md: false });

  useLayoutEffect(() => {
    const header = headerRef.current;
    if (header) {
      setPageHeaderHeight(isMobile ? header.offsetHeight : 0);
    }
  });

  return (
    <Box
      ref={headerRef}
      px={{ base: 0, md: "1rem" }}
      mt={{ base: "0", md: "1.25rem" }}
      position={{ base: "sticky", md: "relative" }}
      top="0">
      <CenteredContent>
        <Breadcrumbs items={breadCrumbItems} />

        <Box
          backgroundColor="#fff"
          borderRadius={{ base: "0", md: "0.375rem" }}>
          <Box
            px={{ base: "0.625rem", md: 0 }}
            pt=".5rem"
            pb={children ? "0" : ".5rem"}
            borderBottomWidth={{ base: "1px", md: "0" }}>
            <Flex align="center">
              {backUrl && (
                <NextLink href={backUrl} passHref>
                  <IconButton
                    as="a"
                    variant="ghost"
                    display={{ base: "flex", md: "none" }}
                    icon={<Icon as={IoArrowBackOutline} boxSize="24px" />}
                    aria-label={intl.formatMessage({
                      defaultMessage: "Retour",
                    })}
                    mr="1rem"
                  />
                </NextLink>
              )}

              <Stack
                w="full"
                direction={{ base: "column", md: "row" }}
                justify="space-between"
                align={{ md: "center" }}
                spacing={{ base: ".5rem", md: "1rem" }}>
                <Text
                  fontSize={{ base: "1.125rem", md: "1.875rem" }}
                  fontWeight={400}
                  lineHeight={{ base: "1.75rem", md: "2.25rem" }}>
                  {title}
                </Text>

                {addon && <Box>{addon}</Box>}
              </Stack>
            </Flex>
          </Box>

          {isValidElement(children) && <>{children}</>}
        </Box>
      </CenteredContent>
    </Box>
  );
}

export default PageHeader;
