import { USERS_USER_TYPE_VALUE_ADMIN } from "@raiden/library/constants/users";
import useAuthAs from "@raiden/library/hooks/auth/as";
import generateAdminUri from "@raiden/library/libraries/utils/generateAdminUri";
import { useRouter } from "next/router";
import { useEffect } from "react";

/**
 * @typedef {Object} Props
 * @property {import("react").ReactNode} children
 *
 * @param {Props} props
 */
function UserGuard({ children }) {
  const { user } = useAuthAs();

  const router = useRouter();

  useEffect(() => {
    if (user?.user_type === USERS_USER_TYPE_VALUE_ADMIN) {
      router.push(generateAdminUri({ id: "dashboard" }));
    }
  }, [router, user?.user_type]);

  return <>{children}</>;
}

export default UserGuard;
