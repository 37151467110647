import {
  Box,
  Icon,
  Link,
  Text,
  useColorModeValue,
  Wrap,
} from "@chakra-ui/react";
import { Fragment } from "react";
import NextLink from "next/link";
import { IoChevronForwardOutline } from "react-icons/io5";

/**
 * @typedef {object} BreadCrumbItem
 * @property {string} title
 * @property {string} [url]
 *
 * @typedef {object} Props
 * @property {BreadCrumbItem[]} [items]
 * @property {boolean} [renderOnMobile]
 *
 * @param {Props} props
 */
function Breadcrumbs({ items = [], renderOnMobile = false }) {
  const color = useColorModeValue("gray.500", "#fff");
  const colorHover = useColorModeValue("brandPrimary.600", "#fff");

  return items.length > 0 ? (
    <Box display={renderOnMobile ? undefined : { base: "none", md: "block" }}>
      <Wrap align="center">
        {items.map?.((item, index) => (
          <Fragment key={index}>
            {item.url !== undefined ? (
              <NextLink href={item.url} passHref>
                <Link
                  // textDecoration="underline"
                  color={color}
                  _hover={{ color: colorHover, textDecoration: "underline" }}>
                  {item.title}
                </Link>
              </NextLink>
            ) : (
              <Text as="span" color={color}>
                {item.title}
              </Text>
            )}

            {index < items.length - 1 && (
              <Icon as={IoChevronForwardOutline} color={color} />
            )}
          </Fragment>
        ))}
      </Wrap>
    </Box>
  ) : null;
}

export default Breadcrumbs;
