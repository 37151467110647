import { Button } from "@chakra-ui/react";
import useAuthAs from "@raiden/library/hooks/auth/as";
import generateFrontPath from "@raiden/library/libraries/utils/generateFrontPath";
import NextLink from "next/link";
import { FormattedMessage } from "react-intl";

function ConnectButton() {
  /** @type {import("@raiden/library/contexts/AuthAs").AuthAsContextValue<import("@raiden/library/types/Customer").Customer>} */
  const { logged } = useAuthAs();

  return (
    <NextLink
      href={
        logged
          ? generateFrontPath({
              id: "@account.dashboard",
            })
          : generateFrontPath({
              id: "@account",
            })
      }
      passHref>
      <Button
        as="a"
        variant="outline"
        borderRadius="full"
        borderColor="gray.700"
        fontSize="1.125rem"
        fontWeight={400}
        lineHeight="1.75rem">
        {logged ? (
          <FormattedMessage defaultMessage="Mon espace" />
        ) : (
          <FormattedMessage defaultMessage="Se connecter" />
        )}
      </Button>
    </NextLink>
  );
}

export default ConnectButton;
