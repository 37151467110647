/**
 * extracts the best year based on the configuration and a given environmentId
 * @param {object} params
 * @param {import("@raiden/library/types/Configuration").Configuration} params.configuration
 * @param {string | number} params.environmentId
 * @returns {import("@raiden/library/types/Configuration").ConfigurationCurrentYear | null}
 **/
export function getPreferredYear({ configuration, environmentId }) {
  const preferredYear = configuration?.years?.find?.((year) => {
    return String(year?.environment_id) === String(environmentId);
  })?.current_year;
  return preferredYear ?? null;
}
