import { Box, Flex } from "@chakra-ui/react";
import generateFrontPath from "@raiden/library/libraries/utils/generateFrontPath";
import { useRouter } from "next/router";
import { useEffect } from "react";
import Footer from "./Footer/Footer";
import HeaderDesktop from "./Header/Desktop";
import HeaderMobile from "./Header/Mobile";

/**
 * @typedef {Object} Props
 * @property {React.ReactNode} children
 *
 * @param {Props} props
 */
function PublicLayout({ children }) {
  const { push } = useRouter();

  useEffect(() => {
    if ((process.env.NEXT_PUBLIC_FRONT_PUBLIC_REDIRECTS ?? "") === "true") {
      push(generateFrontPath({ id: "@account" }));
    }
  }, [push]);

  return (
    <Flex flexDir="column" minH="100vh">
      <HeaderMobile />

      <HeaderDesktop />

      <Box flexGrow={1}>{children}</Box>

      <Footer />
    </Flex>
  );
}

export default PublicLayout;
