import { Box, Stack } from "@chakra-ui/react";
import useInterpolateTranslatedField from "@raiden/library/hooks/useInterpolateTranslatedField";
import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";
import { MENU_PUBLIC_ITEMS_COLORS } from "../../../../../constants/menuPublic";
import {
  getMenuItemAdapterType,
  getMenuItemAdapterValues,
} from "../../../../../helpers/getMenuItemAdapterValues";
import { useConfigurationMenu } from "../../../../../hooks/useConfigurationMenu";
import ConnectButton from "../ConnectButton";
import MenuItem from "./MenuItem";

/**
 * @typedef {Object} Props
 * @property {() => void} onClose
 *
 * @param {Props} props
 */
function HeaderMobileMenu({ onClose }) {
  const { events } = useRouter();

  const translate = useInterpolateTranslatedField();

  const { menu: menuHeader } = useConfigurationMenu({ alias: "header" });

  useEffect(() => {
    events.on("routeChangeComplete", onClose);
  }, [events, onClose]);

  const menuPublicItems = useMemo(() => {
    return menuHeader?.items
      .filter((menuItem) => getMenuItemAdapterType({ menuItem }) !== undefined)
      .map((menuItem) => {
        const values = getMenuItemAdapterValues({ menuItem, translate });
        return {
          title: values.title,
          href: values.href,
          target: values.target,
          isActive: undefined,
        };
      });
  }, [menuHeader?.items, translate]);

  return (
    <Box
      position="fixed"
      inset="80px 0 0 0"
      backgroundColor="#fff"
      p="3rem 1rem">
      <Stack spacing="1.5rem">
        {menuPublicItems?.map((menuItem, index) => (
          <MenuItem
            title={menuItem.title}
            href={menuItem.href}
            color={
              MENU_PUBLIC_ITEMS_COLORS[index % MENU_PUBLIC_ITEMS_COLORS.length]
            }
            isActive={menuItem.isActive}
            target={menuItem.target}
            key={index}
          />
        ))}

        <Box>
          <ConnectButton />
        </Box>
      </Stack>
    </Box>
  );
}

export default HeaderMobileMenu;
