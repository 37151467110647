// deps
import { mode } from "@chakra-ui/theme-tools";

export default {
  baseStyle(props) {
    const { colorScheme } = props;
    return {
      color: colorScheme
        ? mode(`${colorScheme}.600`, `${colorScheme}.300`)(props)
        : mode("gray.700", "whiteAlpha.900")(props),
    };
  },
};
