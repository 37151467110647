import { HIGHLIGHTED_MENU_ITEMS } from "../../../../constants/menu";
import MenuCore from "./MenuCore";
import MenuItemCore from "./MenuItem/MenuItemCore";
import MenuItemHighlighted from "./MenuItem/MenuItemHighlighted";

/**
 * @typedef {object} Props
 * @property {import("./MenuItem/MenuItemCore").Item[]} items
 *
 * @param {Props} props
 */
function MenuHighlighted({ items }) {
  return (
    <MenuCore
      menuItems={HIGHLIGHTED_MENU_ITEMS}
      render={({
        menuItems,
        closeMenuItem,
        openMenuItem,
        openedMenuItemsIds,
      }) => (
        <>
          {menuItems?.map?.((item) => (
            <MenuItemCore
              item={item}
              openedMenuItemsIds={openedMenuItemsIds}
              openMenuItem={openMenuItem}
              closeMenuItem={closeMenuItem}
              render={(params) => <MenuItemHighlighted {...params} />}
              key={item.id}
            />
          ))}
        </>
      )}
    />
  );
}

export default MenuHighlighted;
